import React from 'react'
import Locale from "translations";
export default function Client({ client, index }) {
  const { translate } = Locale;
  const locale = localStorage.getItem("currentLanguage") || "en";
  

debugger
  console.log(
    client,
    " cleanKeys(item); cleanKeys(item); cleanKeys(item); cleanKeys(item); cleanKeys(item);"
  );


  return (
    <div className="border bg-white rounded mt-4">
      <h5 className="font-weight-bold p-3 text_blue-dark mt-2">
        {translate.viewUserData} ({index + 1})
      </h5>

      <div className="  px-3 row">
        <p className="col-3 h5   font-weight-bold">{translate.nameArabic}</p>
        <p className="col-3 h5 ">
          {" "}
          {`${client?.ar_first_name} ${client?.ar_father_name} ${client?.ar_grandpa_name} ${client?.ar_last_name}`}
        </p>

        <p className="col-3 h5   font-weight-bold">{translate.phoneNumber}</p>
        <p className="col-3 h5 ">{client.phone}</p>

        <p className="col-3 h5   font-weight-bold">{translate.EnglishName}</p>
        <p className="col-3 h5 ">
          {`${client?.en_first_name} ${client?.en_father_name} ${client?.en_grandpa_name} ${client?.en_last_name} `}
        </p>
        <p className="col-3 h5  font-weight-bold">{translate.country}</p>
        <p className="col-3 h5 ">مصر</p>
        <p className="col-3  h5  font-weight-bold">
          {translate.passportNumber}
        </p>
        <p className="col-3 h5 ">{client.passport_number}</p>
        <p className="col-3  h5  font-weight-bold">{translate.ExpiryDate}</p>
        <p className="col-3  h5  ">{client.expiration_date}</p>

        <p className="col-3  h5  font-weight-bold">{translate.nationalId}</p>
        <p className="col-3  h5  ">{client.national_id}</p>

        <p className="col-3  h5  font-weight-bold">{translate.Nationality}</p>
        <p className="col-3  h5  ">{client?.country_id?.name_ar}</p>
      </div>
    </div>
  );
}
