import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Locale from "translations";
import WalletLayout from "../WalletLayout";
import Card from "./Card";

export default function RequestInfo() {
  const { wallet } = Locale;
  const history = useHistory();
  const {
    state: { info }
  } = useLocation();

  return (
    <WalletLayout>
      <Card label={wallet.addingRequest}>
        <div className="row info">
          <div className="col-6">{wallet.receiptNumber}</div>
          <div className="col-6">{info.data.receipt_number}</div>

          <div className="col-6">{wallet.requestDate}</div>
          <div className="col-6">{info.data.request_date}</div>

          <div className="col-6">{wallet.status}</div>
          <div className="col-6">{info.data.status}</div>

          <div className="col-6">{wallet.amount}</div>
          <div className="col-6 mb-5">{info.data.amount}</div>
          <button onClick={() => history.push("/wallet")} className="back mt-5">
            {wallet.backToRequests}
          </button>
        </div>
      </Card>
    </WalletLayout>
  );
}
