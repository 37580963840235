import React, { useState }from "react";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/Admin/textField";
import Locale from "../../../translations";

export default function Filter(props) {
  const { translate, translate_companies } = Locale;

  const [searchState, setSearchState] = useState('');
  const [statusState, setStatusState] = useState('0');

  const searchChangedHandler = (event) => {
    setSearchState(event.target.value);
  }

  const statusChangedHandler = (event) => {
    //setStatusState(event.target.value);
    console.log(event.value);
    if(event.value !== '0') {
        setStatusState(event.value);
    }
  }

  const searchSubmitHandler = () => {
    console.log('status',statusState);
    console.log('search',searchState);
    props.FilterSubmitted(statusState,searchState);
  }

  const resetSearchHandler = () => {
    setStatusState('0');
    setSearchState('');
    props.ResetSubmitted();
  }

  const options = [
    { value : '0', label: translate.status, isDisabled: true },
    { value: 'active', label: translate_companies.active },
    { value: 'inactive', label: translate_companies.deactive }
  ];

  return (
    <div className="row no-gutters" style={{width:"60% "}}>
      <div className="col mr-2 ">
        <TextField
          hasLabel={false}
          search={true}
          value={searchState}
          onChange={(event) => searchChangedHandler(event)}
          label={translate.search}
          type={"text"}
          placeholder={translate.search}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
      
      <div className="col mr-2  ">
        <SelectField
          hasLabel={false}          
          placeholder={translate.status}
          options={options}
          defaultValue={statusState}
          onChange={(event)=>statusChangedHandler(event)}
        />
      </div>

      <div className="col  pt-1" >
        <button onClick={searchSubmitHandler} className="Adminfilter-button btn btn-primary px-5 py-2 font-weight-bold">
          {translate.search}
        </button>
        <button onClick={resetSearchHandler} className="btn text-muted font-weight-bold ">
          {" "}
           <i className="fas fa-retweet"></i>{translate.reset}
        </button>
      </div>
    </div>
);
}
