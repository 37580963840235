import UserSetting from "components/UsetSetting/Admin/UserSetting";
import { useAdminDispatch, useAdminState } from "context/Admin/global";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Locale from "translations";
import logoImg from "../../../assets/images/Image1.png";
import Settings from '../../../assets/images/settings.svg';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
function Header() {
  const { translate, Admin } = Locale;
  const { pathname } = useLocation();

  const AdminIsAuth = localStorage.getItem("Admin_isAuth");
  const dispatch = useAdminDispatch();
  const { balance } = useAdminState();

  return !AdminIsAuth ? null : (
    <>
      <div className="w-100 bg-white pt-2">
        <div className="container  ">
          <div className="d-flex justify-content-between align-items-center">
            <figure className="logo">
              <img className="w-100" src={logoImg} alt="logo" />
            </figure>
            <div className="header-icons">
              {/* <button
                onClick={() => {
                  dispatch({ type: "logout", payload: "logout" });
                }}
              >
                <i class="fas fa-sign-out-alt"></i>
              </button> */}

              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="button" className="h5 text-primary d-flex align-items-center" caret>
                <img src={Settings} className="Header-Setting-img" alt="Settings" />
                <span className="Header-Setting-title">{Admin.settings}</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    tag="button"
                    onClick={() => {
                      dispatch({ type: "logout", payload: 'logout' });
                    }}
                    className="text-right"
                  >
                  {Admin.logout}
                  </DropdownItem>
              
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </div>

      <nav className="header ">
        <div className="d-flex justify-content-between align-items-stretch  container ">
          <div className="d-flex  ">
            <NavLink
              exact={true}
              to="/admin/home"
              isActive={() => pathname.includes("home")}
              activeClassName="is-active"
              className="mr-4 py-2 text-bold AdminHeaderLink"
            >
              {Admin.header_home}
            </NavLink>

            <div className="subnav">
              <button className={pathname.includes("companies") ? 'subnavbtn subnavbtn-active mr-4 py-2 text-bold AdminHeaderLink' : 'subnavbtn mr-4 py-2 text-bold AdminHeaderLink'}>
                {Admin.header_companies} <i class="fa fa-caret-down"></i>
              </button>
              <div className="subnav-content">
                <NavLink
                  exact={true}
                  to="/admin/companies"
                  isActive={() => pathname.includes("companies")}
                  activeClassName="is-active"
                  className="mr-4 py-2  text-bold "
                >
                  {Admin.active_company}
                </NavLink>

                <NavLink
                  exact={true}
                  to="/admin/companies-requests"
                  isActive={() => pathname.includes("companies")}
                  activeClassName="is-active"
                  className="mr-4 py-2  text-bold"
                >
                  {Admin.request_company}
                </NavLink>
              </div>
            </div>

            <NavLink
              exact={true}
              to="/admin/team-management/users"
              isActive={() => pathname.includes("team")}
              activeClassName="is-active"
              className="mr-4 py-2 text-bold AdminHeaderLink"
            >
              {Admin.header_team_management}
            </NavLink>

            <NavLink
              exact={true}
              to="/admin/wallet"
              isActive={() => pathname.includes("admin/wallet")}
              activeClassName="is-active"
              className="mr-4 py-2 text-bold AdminHeaderLink"
            >
              {Admin.header_balance_requests}
            </NavLink>

            <NavLink
              exact={true}
              to="/admin/orders-inprogress"
              isActive={() => (pathname.includes("admin/medical-services-requests") || pathname.includes("admin/orders-inprogress"))}
              activeClassName="is-active"
              className="mr-4 py-2 text-bold AdminHeaderLink"
            >
              {Admin.medical_services_requests}
            </NavLink>

            <NavLink
              exact={true}
              to="/admin/accounts"
              isActive={() => (pathname.includes("accounts"))}
              activeClassName="is-active"
              className="mr-4 py-2 text-bold AdminHeaderLink"
            >
              {Admin.accounts}
            </NavLink>
          </div>

        </div>
      </nav>
    </>
  );
}
export default Header;
