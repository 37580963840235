import React, { useState, useEffect } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';

import Locale from "../../translations";
import { useAdminState } from "../../context/Admin/global";

import { getAdminStatistics } from "../../services/admin";

const Home = (props) => {
  const { home } = Locale;
  const [statistics, setStatistics] = useState();
  const { currentLanguage } = useAdminState();

  useEffect(() => {
    async function fetchData() {
      const allStatistics = await getAdminStatistics();
      console.log(allStatistics?.data?.data);
      setStatistics(allStatistics?.data?.data);
    }
    fetchData();
  }, []);

  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: [home.incoming_requests, home.running, home.checked],
    datasets: [
      {
        label: '',
        data: [statistics?.reservations.nulled, statistics?.reservations.inprogress, statistics?.reservations.finished],
        backgroundColor: [
          '#24AEFB',
          '#F28F31',
          '#00B437',
        ],
        borderColor: [
          '#24AEFB',
          '#F28F31',
          '#00B437',
        ],
        borderWidth: 1,
      },
    ],
  };

  let DateFormat = '';
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  if (currentLanguage === 'ar') {
    DateFormat = new Date().toLocaleDateString('ar-eg', options);
  }
  else {
    DateFormat = new Date().toLocaleDateString('fr-eg', options);
  }

  return (
    <section>
      <div className="container  my-4 ">
        <div className="HomeContainer">
          <div className="HomeCompanyContainer">
            <div className="HomeCompanyCount">
              <div className="HomeCompanyHeader">
                <h2 className="HomeCompanyHeaderTitle">{home.company_count}</h2>
                <div className="HomeCompanyLine"></div>
              </div>
              <div className="HomeCompanyContent">
                <i className="fa fa-building HomeCompanyIcon" aria-hidden="true"></i>
                <span className="HomeCompanyNumber">{statistics ? statistics['companies-number'] : null}</span>
              </div>
            </div>
          </div>

          <div className="HomeUsersContainer">
            <div className="HomeUsersChar">
              <div className="HomeUsersHeader">
                <h2 className="HomeUsersHeaderTitle">{home.company_clients}</h2>
                <div className="HomeCompanyLine"></div>
              </div>
              <div className="HomeUsersContent">
                <div className="HomeUserStatistics">
                  <Doughnut data={data} />
                  <span className="HomeUserStatisticsTitle">{home.total_requests}</span>
                  <span className="HomeUserStatisticsCount">{statistics ? statistics.reservations.nulled + statistics.reservations.inprogress + statistics.reservations.finished : null}</span>
                </div>
                <div className="HomeUserCount">
                  <div className="HomeUserCountRow">
                    <i className="fa fa-circle HomeUserCountIncomingIcon" aria-hidden="true"></i>
                    <div className="HomeUserCountContent">
                      <span className="HomeUserCountContentTitle">{home.incoming_requests}</span>
                      <span className="HomeUserCountContentNumber">{statistics?.reservations.nulled}</span>
                    </div>
                  </div>

                  <div className="HomeUserCountRow">
                    <i className="fa fa-circle HomeUserCountRunningIcon" aria-hidden="true"></i>
                    <div className="HomeUserCountContent">
                      <span className="HomeUserCountContentTitle">{home.running}</span>
                      <span className="HomeUserCountContentNumber">{statistics?.reservations.inprogress}</span>
                    </div>
                  </div>

                  <div className="HomeUserCountRow">
                    <i className="fa fa-circle HomeUserCountCheckedIcon" aria-hidden="true"></i>
                    <div className="HomeUserCountContent">
                      <span className="HomeUserCountContentTitle">{home.checked}</span>
                      <span className="HomeUserCountContentNumber">{statistics?.reservations.finished}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="HomeLogContainer">
            <div className="HomeLogContent">
              <div className="HomeLogHeader">
                <h2 className="HomeLogHeaderTitle">{home.my_activity}</h2>
                <p className="HomeLogHeaderSubTitle">{home.record_order}</p>
                <div className="HomeCompanyLine"></div>
              </div>
              <div className="HomeLoginContent">
                <div className="HomeTimeLineDayContainer">
                  <div className="HomeTimeLineDay">
                    {DateFormat}
                  </div>
                </div>
                {statistics ? Object.values(statistics.logs).map(data => {
                  return (<React.Fragment>
                    <Timeline align="left">
                      <TimelineItem>
                        <TimelineOppositeContent>
                          <Typography className="TimelineDate">{new Date(data.login_at).getHours() + ':' + (new Date(data.login_at).getMinutes() < 10 ? '0' : '') + new Date(data.login_at).getMinutes()}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className="TimeLineDote" />
                          <TimelineConnector className="TimeLineLine" />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography className="TimeLineLoginTitle">{home.login}</Typography>
                          <Typography className="TimeLineLocationTitle">{data.location.city + ' - ' + data.location.country}</Typography>
                          <Typography className="TimeLineIPTitle">{data.ip_address}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </React.Fragment>);
                }) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
