import React, { useEffect, useState } from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";

import FilterGroup from "./filterGroup";
import { NavLink } from "react-router-dom";
import Collaps from "./Collapse"
import UserNumber from "./userNumber"
import { ListGroups, ListUser } from "../../../services/yarvel";

export default function Groups() {
  const { translate } = Locale;
  let history = useHistory();
  const [Groups, setGroups] = useState([])
  const [users, setUsers] = useState([]);


  useEffect(() => {
  	async function fetchData() {
			const User = await ListGroups();
			setGroups(User?.data);
		}
		fetchData();

    async function fetchUserData() {
			const User = await ListUser();
			setUsers(User?.data.data);
		}
		fetchUserData();
  }, []);


  const AllGroups =
  Groups && Groups?.data?.length > 0
    ? Groups?.data?.map((Group, index) => (
        <Collaps
        Group={Group}
          index={index}
        />
      ))
    : "";

  return (
    <section>
       <UserNumber users={users}/>
      <div className="container">
        <div className="d-flex justify-content-between mt-5 mb-2">
      <h3 className="text-muted font-weight-bold">{translate.teamManagement}</h3>

            
             <NavLink
                exact={true}
                to={`/team-management/groups/add`}
                activeClassName="is-active"
              
              >
              <button
              type="button"
              className="btn btn-success px-4 py-2 font-weight-bold "
              onClick={() => {
                history.push("/team-management/groups");
              }}
            >
              <i className="fas fa-plus-circle mr-2"></i>
              {translate.addGroup}
            </button>
              </NavLink> 
        </div>
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2  ">
          <FilterGroup />
          <div>
                <NavLink
                  exact={true}
                  to={`/team-management/users`}
                  activeClassName="is-active"
                  className="btn-nav  mx-1 px-3 bg-brwon rounded text-muted"
                >
                  {translate.users}
                </NavLink>



                <NavLink
                  exact={true}
                  to={`/team-management/groups`}
                  activeClassName="is-active"
                  className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
                >
                  { translate.groups}
                </NavLink>
               
          </div>
        </header>



        <div className="Collapse">
          {AllGroups}
        </div>
        
        
      </div>
    </section>
  );
}

