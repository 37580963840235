import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import Locale from "translations";
import { Tooltip } from "reactstrap";
import moment from "moment";

function Traveler({ client }) {
  const { translate } = Locale;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { id } = useParams();
  let history = useHistory();
  return (
    <tr>
      <td>{client.id}</td>
      <td>{client.test_name}</td>
      <td>{client.traveler_name}</td>
      <td>{client.type}</td>
      <td>{client.grade_name}</td>
      <td>{client.created_at}</td>
      <td>{client.total_cost} </td>
      <td
        className={`font-weight-bold ${
          /*       client.payment_status === "paid" || */ client.payment_status ===
          "مدفوع"
            ? "text-success"
            : /* client.payment_status === "unpaid" || */
            client.payment_status === "غير مدفوع"
            ? " text-danger"
            : " "
        }`}
      >
        {client.payment_status}
      </td>
      {/*      <td>{client.remaining_cost}</td> */}
      <td>
        {client.check_date_end
          ? moment(client.check_date_end, "YYYY-MM-DD HH:mm aa").format(
              "YYYY-MM-DD"
            )
          : " "}
        <p className="m-0">
          {client.check_date_end
            ? moment(client.check_date_end, "YYYY-MM-DD HH:mm aa").format(
                "HH:mm"
              )
            : " "}
        </p>
      </td>

      <td className="d-flex">

          <button
            className="btn btn-outline-primary d-flex align-items-center  justify-content-center p-1 "
            onClick={() => {
              history.push(`/view-result/${client.trip_id}`);
            }}
          >
            <i class="fas fa-eye mx-1 "></i>
            <p className="m-0 mx-1">{translate.view}</p>
          </button>
        

        {
          /* client?.payment_status === "paid" || */
          client.payment_status === "مدفوع" && id != "done" ? (
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="Tooltip"
              toggle={toggle}
              className={"bg-info"}
              innerClassName={"bg-info"}
              arrowClassName={"bg-info"}
              hideArrow={true}
            >
              <span className="lan">{translate.CantModified}</span>
            </Tooltip>
          ) : (
            " "
          )
        }

        {id != "done" && (
          <button
            /* disabled={client.payment_status != "paid"} */
            className=" btn btn-outline-primary d-flex align-items-center  justify-content-center p-1 mx-2"
            id={`${
              /*               client?.payment_status === "paid" || */
              client.payment_status === "مدفوع" ? "Tooltip" : " "
            }`}
            onClick={() => {
              if (
                /*                 client.payment_status != "paid" && */
                client.payment_status != "مدفوع"
              ) {
                history.push(`/medical/services/${client.trip_id}`);
              }
            }}
          >
            <i class="fas fa-edit mx-1"></i>
            <p className="m-0 mx-1">{translate.edit}</p>
          </button>
        )}
      </td>
    </tr>
  );
}

export default Traveler;
