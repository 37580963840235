import { useCompanyState } from "context/global";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
import Filter from "./Filter";
import useWallet from "./useWallet";
import "./walletYarvel.scss";
import WalletLayout from "./WalletLayout";
import WalletRequests from "./WalletRequests";
import { useHistory } from "react-router";
import WalletTransfer from "./WalletTransfer";


export default function Wallet() {
  const { wallet, translate, translate_companies } = Locale;
  const { balance, safaWallet, safaLimit } = useCompanyState();
  const [currentTab, setCurrentTab] = useState("not-approved");
  const { requests, getRequests, getRequestsTransaction } = useWallet();
  const company_id = localStorage.getItem("company_id");
  let history = useHistory();

  const [filter, setfilter] = useState({
    transaction_number: "",
    transaction_date: "",
    type: null,
  });
  const [submiting, setsubmiting] = useState(false);

  const sumbit = () => {
    if (currentTab === "transfer") {
      getRequestsTransaction(company_id, filter);
    } else if (currentTab === "not-approved") {
      getRequests("not-approved", filter);
    }
  };

  useEffect(() => {
  
      sumbit();
    
  }, [submiting]);

  return (
    <WalletLayout>
      <div className="container">
        <div className="row mt-3 mb-5 align-items-end ">
          <div className=" col-3">
            <h2 className="walletBalanceHeader BalanceHeader">
              {translate.Balance}
            </h2>
            <div className="walletBalanceContent BalanceContent">
              <div className="walletBalanceAmountContent AmountContent">
                <h2 className="walletBalanceAmountTitle AmountTitle">
                  {translate_companies.current_balance}
                </h2>
                <p className="walletBalanceAmountNumber text-success AmountNumber">
                  {balance}
                </p>
                <span className="walletBalanceAmountPound AmountPound">
                  {translate_companies.egyptian_pound}
                </span>
              </div>
            </div>
          </div>
          <div className=" col-4 offset-2">
            <h2 className="walletBalanceHeader BalanceHeader">
              {translate_companies.safa_balance}
            </h2>
            <div className="walletBalanceContent BalanceContent">
              <div className="walletBalanceAmountContent AmountContent">
                <h2 className="walletBalanceAmountTitle AmountTitle">
                  {translate_companies.remaining_balance +
                    " / " +
                    translate_companies.total_balance}
                </h2>
                <p className="walletBalanceAmountNumber text-success AmountNumber">
                  {safaWallet != undefined ? safaWallet + " / " : 0 + " / "}
                  <span className="walletBalanceAmountNumberSpan AmountNumberSpan">
                    {safaLimit != undefined ? safaLimit : 0}
                  </span>
                </p>
                <span className="walletBalanceAmountPound AmountPound">
                  {translate_companies.egyptian_pound}
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column  col-2 ">
            <button
              type="button"
              class="rounded p-1 btn-success my-1 "
              onClick={() => {
                history.push("/wallet/add");
              }}
            >
              <i class="fas fa-money-bill-wave fa-2x"></i>
              <p className="h5">{translate_companies.add_credit}</p>
            </button>
          
              <button
                type="button"
                class="rounded p-1 btn-success my-1"
                onClick={() => {
                  history.push("/wallet/transfer");
                }}
              >
                <i class="fas fa-exchange-alt fa-2x"></i>
                <p className="h5">{translate.TransferAccounts}</p>
              </button>
            
          </div>
        </div>
        <div className="row tabs">
          <button
            className={`tab ${currentTab === "transfer" ? "active" : ""}`}
            onClick={() => {
              setCurrentTab("transfer");
              getRequestsTransaction(company_id, filter);
            }}
          >
            <i className="fas fa-exchange-alt fa-lg mx-2"></i>
            {wallet.deals}
          </button>
          <button
            className={`tab ${currentTab === "not-approved" ? "active" : ""}`}
            onClick={() => {
              setCurrentTab("not-approved");
              getRequests("not-approved", filter);
            }}
          >
            <i className="fas fa-file-alt fa-lg mx-2"></i>
            {wallet.requests}
          </button>
        </div>
        <Filter
          filter={filter}
          setfilter={setfilter}
          sumbit={sumbit}
          currentTab={currentTab}
          setsubmiting={setsubmiting}
          submiting={submiting}
        />
        <div className="row requests">
          {currentTab === "transfer" && <WalletTransfer requests={requests} />}
          {currentTab === "not-approved" && (
            <WalletRequests requests={requests} />
          )}
        </div>
      </div>
    </WalletLayout>
  );
}
