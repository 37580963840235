import { useCompanyDispatch } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getCurrentBalance,
  getWalletRequest,
  WalletTransactionLogs,
} from "services/wallet";

const useWallet = () => {
  const [balance, setBalance] = useState(0);
  const [balanceCredit, setBalanceCredit] = useState(0);
  const [requests, setRequests] = useState([]);
  const dispatch = useCompanyDispatch();

  const getBalance = async () => {
    const res = await getCurrentBalance();
    if (res?.data && res?.data.length > 0) {
      res?.data.map((res) => {
        if (res.type == "wallet") {
          setBalance(res?.amount);
          dispatch({
            type: "setBalance",
            balance: res?.amount,
          });
        } else if (res.type == "credit") {
          setBalanceCredit(res?.amount);
          dispatch({
            type: "setBalanceCredit",
            balance: res?.amount,
            limit: res?.limit,
          });
        }
      });
    }
  };

  const getRequests = async (status = "not-approved", filter) => {
    
    const res = await getWalletRequest(status, {
      ...filter,
      receipt_number: filter?.transaction_number,
      receipt_date: filter?.transaction_date
        ? moment(filter?.transaction_date).format("YYYY-MM-DD")
        : null,
      status: filter?.type ? filter.type.value : null,
      type: "",
      transaction_number: "",
      transaction_date:"",
    });
    setRequests(res.data);
  };

  const getRequestsTransaction = async (id, filter) => {
    const res = await WalletTransactionLogs(id, {
      ...filter,
      transaction_date: filter?.transaction_date
        ? moment(filter?.transaction_date).format("YYYY-MM-DD")
        : null,
      type: filter?.type ? filter.type.value : null,
    });

    setRequests(res.data.data);
  };

  useEffect(() => {
    getBalance();
    getRequests();
  }, []);

  return {
    balance,
    balanceCredit,
    requests,
    getRequests,
    getBalance,
    getRequestsTransaction,
  };
};

export default useWallet;
