import React, { useState }from "react";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/Admin/textField";
import Locale from "../../../translations";

export default function Filter(props) {
  const { translate } = Locale;

  const [searchState, setSearchState] = useState('');

  const searchChangedHandler = (event) => {
    setSearchState(event.target.value);
  }

  const searchSubmitHandler = () => {
    console.log('search',searchState);
    props.FilterSubmitted(searchState);
  }

  const resetSearchHandler = () => {
    setSearchState('');
    props.ResetSubmitted();
  }

  return (
    <div className="CompanyFilterContainer">
    <div className="row no-gutters " style={{width:"40% "}}>
      <div className="col mr-2 ">
        <TextField
          hasLabel={false}
          search={true}
          value={searchState}
          onChange={(event) => searchChangedHandler(event)}
          label={translate.search}
          type={"text"}
          placeholder={translate.search}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>

      <div className="col  pt-1" >
        <button onClick={searchSubmitHandler} className="btn btn-primary px-5 py-2 font-weight-bold">
          {translate.search}
        </button>
        <button onClick={resetSearchHandler} className="btn text-muted font-weight-bold ">
          {" "}
           <i className="fas fa-retweet"></i>{translate.reset}
        </button>
      </div>
      
    </div>
</div>
);
}
