import React, { useState, useEffect } from "react";

import Locale from "../../../translations";
import { Table } from "reactstrap";

import { ListAccounts } from "../../../services/admin";
import Order from "./Order";
import Filter from "./filter";

import Pagination from "components/shared/Pagination";

export default function Orders(props) {
  const { Accounts } = Locale;

  const [orders, setOrders] = useState([]);
  const [ordersMeta, setOrdersMeta] = useState(null);
  const [filter, setFilter] = useState({
    company_name: '',
    check_type: '',
    payment_date: '',
    payment_method: '',
    page: 1,
  });

  useEffect(() => {
    async function fetchData() {
      const Order = await ListAccounts(filter);
      setOrders(Order?.data?.data);
      setOrdersMeta(Order?.data?.meta);
    }
    fetchData();
  }, [filter]);

  // pagination
  const goTo = async (page) => {
    setFilter({ ...filter, page: page });
  };

  const AllOrders =
    orders && orders?.length > 0
      ? orders?.map((order, index) => (
        <Order
          order={order}
          index={index}
        />
      ))
      : "";

  const filterSubmitted = (dateQ, searchCompanyNameQ, typeExaminationState, methodPaymntState) => {
    let Type_Examination = '';
    let Method_Paymnt = '';

    if(typeExaminationState !== '0'){
      Type_Examination = typeExaminationState;
    }
    if(methodPaymntState !== '0'){
      Method_Paymnt = methodPaymntState;
    }

    setFilter({ ...filter, payment_date: dateQ, company_name: searchCompanyNameQ, payment_method: Method_Paymnt, check_type: Type_Examination, page: 1 });

  }

  const resetSubmitted = () => {
    setFilter({ ...filter, payment_date: '', company_name: '', payment_method: '', check_type: '', page: 1 });
  }

  return (
    <React.Fragment>
      <Filter
        ResetSubmitted={() => resetSubmitted()}
        FilterSubmitted={(date, searchCompanyNameQ, typeExaminationState, methodPaymntState) => filterSubmitted(date, searchCompanyNameQ, typeExaminationState, methodPaymntState)} />

      <div className=" mt-3 p-0 CompaniesList-table">
        <Table>
          <thead>
            <tr className="CompaniesList-head">
              <th className="AccountsHead-title"> {Accounts.company_name}</th>
              <th className="AccountsHead-title"> {Accounts.order_number}</th>
              <th className="AccountsHead-title">{Accounts.date}</th>
              <th className="AccountsHead-title">{Accounts.customer_name}</th>
              <th className="AccountsHead-title">{Accounts.type_examination}</th>
              <th className="AccountsHead-title">{Accounts.service_provider}</th>
              <th className="AccountsHead-title">{Accounts.Cost}</th>
              <th className="AccountsHead-title">{Accounts.checkme_cost}</th>
              <th className="AccountsHead-title">{Accounts.company_ratio}</th>
              <th className="AccountsHead-title">{Accounts.yaravel_ratio}</th>
              <th className="AccountsHead-title">{Accounts.deduction_wallet}</th>
              <th className="AccountsHead-title">{Accounts.date_payment}</th>
              <th className="AccountsHead-title">{Accounts.method_paymnt}</th>
              <th className="AccountsHead-title">{Accounts.wallet_balance}</th>
            </tr>
          </thead>
          <tbody className="table-stripe ">{AllOrders}</tbody>
        </Table>
        <Pagination info={ordersMeta} goTo={goTo} items={10} />
      </div>
    </React.Fragment>
  );
}
