import React from "react";
import Locale from "../../../translations";

export default function TextField(props) {
  const { translate } = Locale;

  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(translate.isRequired);
        } else if (k === "min" && v) {
          //message.push(` should be at least ${props.min} chars`);atLeast
          message.push(
            `${translate.atLeast} ${props.min}  ${translate.entries}`
          );
        } else if (k === "maxNumber" && v) {
          message.push(` should not exceed ${props.max}`);
        } else if (k === "minNumber" && v) {
          message.push(` ${translate.atLeast} ${props.min}`);
        } else if (k === "email" && v) {
          message.push(translate.shouldBeValid);
        } else if (k === "phone" && v) {
          message.push(translate.shouldBeValid);
        } else if (k === "number" && v) {
          message.push(translate.shouldBeNumber);
        } else if (k === "formula" && v) {
          message.push(translate.shouldBeFormula);
        }
      }

      if (message.length === 1) {
        return `${props.label} ${message[0]}`;
      } else if (message.length > 1) {
        return `${props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };

  return (
    <div
      className={`Admintext_filed mb-4 
      ${props.search ? "Admin-search" : ""} 
      ${props.disabled ? "bg-disabled" : " bg-white"} ${props.color ? "error_border" : ""}`}
    >
      <div className="d-flex align-items-center">
        <div className="w-100 d-flex flex-column justify-content-center ">
          <div>
            {props.hasLabel ? (
              <label
                className={`control-field${
                  props.color ? ` text-${props.color}` : ""
                }`}
              >
                {props.label}
              </label>
            ) : null}
          </div>

          <input
            /*             readonly={props.readonly} */
            type={props.type}
            name={props.name}
            className={`${props.className ?? ""} text_input ${
              props.disabled ? "bg-disabled" : "bg-white"
            } `}
            id={props.id}
            value={props.value}
            min={props.min}
            max={props.max}
            disabled={props.disabled}
            onChange={props.onChange}
            onKeyUp={props.onKeyUp}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            autoComplete="off"
            onKeyDown={props.onEnter}
          />
        </div>
        <div
          className={` ${
            props.hasLabel ? "icons_label px-2 mb-2" : "icons px-2 mb-2"
          }
             ${props.color ? "text-danger" : " "}
            `}
        >
          {props.children ? props.children : null}
        </div>
      </div>
      {/* Error Message */}
      <small className={` text-${props.color} position-absolute`}>
        {generateErrorsMsgs(props.errors)}
      </small>
    </div>
  );
}
