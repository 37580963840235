import React, { useEffect, useState } from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";

import FilterGroup from "./filterGroup";
import { NavLink } from "react-router-dom";
import Collaps from "./Collapse"
import UserNumber from "./userNumber"
import { ListGroups, ListUser } from "../../../services/admin";
import Pagination from "components/shared/Pagination";

export default function Groups() {
  const { translate } = Locale;
  let history = useHistory();
  const [Groups, setGroups] = useState([])
  const [GroupsMeta, setGroupsMeta] = useState();
  const [users, setUsers] = useState([]);

  const [filter, setFilter] = useState({
    type: localStorage.getItem('Admin_userType'),
    status: '',
    search: '',
    paginated: 1
  });

  const [filterUser, setFilterUser] = useState({
    admin_type: localStorage.getItem('Admin_userType'),
    status: '',
    q: ''
  });

  useEffect(() => {
    async function fetchData() {
      const User = await ListGroups(filter);
      setGroups(User?.data);
      setGroupsMeta(User?.meta);
    }
    fetchData();

    async function fetchUserData() {
      const User = await ListUser(filterUser);
      setUsers(User?.data.data);
    }
    fetchUserData();
  }, [filter,filterUser]);


  const AllGroups =
    Groups && Groups?.data?.length > 0
      ? Groups?.data?.map((Group, index) => (
        <Collaps
          Group={Group}
          index={index}
        />
      ))
      : "";

  const filterSubmitted = (searchQ) => {
    console.log(searchQ);
    setFilter({ ...filter, search: searchQ, page: 1 });
    setFilterUser({ ...filterUser,  q: searchQ, page: 1 })
  }

  const resetSubmitted = () => {
    setFilter({ ...filter,  search: '', page: 1 });
    setFilterUser({ ...filterUser,  q: '', page: 1 })
  }

      // pagination
      const goTo = async (page) => {
        setFilter({ ...filter, page: page });
      };

  return (
    <section>
      <UserNumber users={users} />
      <div className="container">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate.teamManagement}</h3>


          {/* <NavLink
            exact={true}
            to={`/admin/team-management/groups/add`}
            activeClassName="is-active"

          >
            <button
              type="button"
              className="btn btn-success px-4 py-2 font-weight-bold "
              onClick={() => {
                history.push("/admin/team-management/groups");
              }}
            >
              <i className="fas fa-plus-circle mr-2"></i>
              {translate.addGroup}
            </button>
          </NavLink> */}
        </div>

        <header className="d-flex justify-content-between Adminlist_header border py-3 px-2  ">
          <FilterGroup
            ResetSubmitted={() => resetSubmitted()}
            FilterSubmitted={(searchQ) => filterSubmitted(searchQ)} />
          <div>
            <NavLink
              exact={true}
              to={`/admin/team-management/users`}
              activeClassName="is-active"
              className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted AdminNot-active"
            >
              {translate.users}
            </NavLink>
            <NavLink
              exact={true}
              to={`/admin/team-management/groups`}
              activeClassName="is-active"
              className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
            >
              {translate.groups}
            </NavLink>
          </div>
        </header>

        <div className="Collapse">
          {AllGroups}
        </div>
        <Pagination info={GroupsMeta} goTo={goTo} items={10} />

      </div>
    </section>
  );
}

