import React,{ useState, useEffect } from "react";
import Locale from "../../../../translations";
import {  NavLink, useParams } from "react-router-dom";
import Orders from "./orders";
import {
    RetrievedCompany
  } from "../../../../services/admin";

function CompanyOrders() {
  const { translate_companies } = Locale;
  const [companyName, setCompanyName] = useState('');

  const { company_id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const Company = await RetrievedCompany(company_id);
      console.log('Company', Company.data.companies);
      setCompanyName(Company.data.companies.name);
    }
    fetchData();
  }, [company_id]);

  return (
    <section>
      <div className="AdminContainer-custom container ">
        <header className="d-flex CompaniesList-orders py-3 px-2  ">
        <div>
          <h3 className="text-muted font-weight-bold CompaniesList-title">{translate_companies.orders_header_title}{'('+companyName+')'}</h3>
        </div>
          <div>
            <NavLink
              exact={true}
              to={`/admin/companies/orders-inprogress/`+company_id}
              activeClassName="is-active"
              className="text-bold mx-1 px-3 bg-brwon rounded text-muted CompaniesList-LinkNotActive"
            >
              {translate_companies.orders_working}
            </NavLink>
            <NavLink
              exact={true}
              to={`/admin/companies/orders/`+company_id}
              activeClassName="is-active"
              className="text-bold mx-1 px-3 bg-brwon rounded text-muted CompaniesList-LinkActive"
            >
              {translate_companies.orders_checked}
            </NavLink>
          </div>
        </header>
        <Orders companyID={company_id} />
      </div>
    </section>
  );
}

export default CompanyOrders;
