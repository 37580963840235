import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Locale from "translations";

const SelectField = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none"
    })
  };

  const selectRef = useRef(null);

  const { translate } = Locale;

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target))
      setIsOpened(false);
    else setIsOpened(true);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, []);

  if (props.extruded) {
    if (props.options) {
      props.options.forEach((element) => (element.disabled = false));
      props.options.forEach((element) => {
        props.extruded.forEach((ele) => {
          if (ele.value == element.id) {
            element.disabled = true;
          }
        });
      });
    }
  }

  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(translate.isRequired);
        } else if (k === "min" && v) {
          message.push(` should be at least ${props.min} digits`);
        } else if (k === "email" && v) {
          message.push(`should be valid`);
        }
      }
      if (message.length === 1) {
        return `${props.label} ${message[0]}`;
      } else if (message.length > 1) {
        return `${props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };

  return (
    <div className="">
      <div className={`${props.color ? " control-field--" + props.color : ""}`}>
        {/* Text Field Body */}
        <div
          className={`${props.disabled ? "isDisabled" : ""}`}
          onClick={() => setIsOpened(!isOpened)}
          ref={selectRef}
        >
          <div className={`row no-gutters`}>
            {props.hasLabel ? (
              <label
                className={` control-field__label border-${props.color} text-${props.color}`}
              >
                {props.label}
              </label>
            ) : null}

            <Select
              styles={style}
              className={` ${
                props.hasLabel ? "select_field--label" : "select_field"
              } border border-${props.color}   ${props.disabled ? "isDisabled" : ""}`}
              id={props.id}
              hasLabel={props.hasLabel}
              value={props.value}
              name={props.name}
              menuIsOpen={props.disabled ? false : isOpened}
              isMulti={props.multi}
              isSearchable={props.isSearchable}
              isDisabled={props.disabled}
              placeholder={props.placeholder ? props.placeholder : ""}
              onChange={props.onChange}
              onClick={props.onClick}
              options={props.options || []}
              onBlur={props.onBlur}
              isClearable={false}
              isOptionDisabled={(option) =>
                props.Disabled ? props.Disabled : false
              }
              defaultValue={props.defaultValue}
            />
          </div>
        </div>
      </div>

      {/* Feedback */}
      <small
        className={`control-field__feedback control-field__feedback--${
          props.color
        } ${props.isInvalid ? "d-block" : "d-none"}`}
      >
        {props.feedbackMessage}
      </small>
      <small className={` text-${props.color} position-absolute`}>
        {generateErrorsMsgs(props.errors)}
      </small>
    </div>
  );
};

SelectField.defaultProps = {
  hasLabel: true
};

export default SelectField;
