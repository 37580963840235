import emailImg from "assets/images/mailbox.svg";
import { useAuthDispatch, useAuthState } from "context/auth";
import React from "react";
import { Spinner } from "reactstrap";
import Locale from "translations";

export default function EmailConfirmation(props) {
  // ----------------------------------------------------------------------------------------------------
  // Component States / Variables / Constants
  // ----------------------------------------------------------------------------------------------------
  const { emailConfirmation } = Locale;
  const userEmail = useAuthState();
  const dispatch = useAuthDispatch();

  // Back Button
  const back = () => {
    debugger
    dispatch({ type: "Progress", progress: 50, step: 2 });
  };
  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------
  return (
    <>
      <h1 className="text-center text-title text-dark-blue">
        {emailConfirmation.mainTitle}
      </h1>

      <div className="bg-white p-2 px-3 radius-10 mt-3">
        <div className="row">
          <div className="col-md-6 mx-auto text-center py-4">
            <img
              src={emailImg}
              alt="email-verification"
              title="email-verification"
            />
            <h4 className="text-subtitle text-red mt-3">
              {emailConfirmation.emailVerificationTitle}
            </h4>
            <h5 className="text-title text-dark-blue mt-2">
              {userEmail.userDetails?.email}
            </h5>
          </div>
        </div>
      </div>

      <div className="row mt-3 m-0">
        <div className="col-md-2 offset-md-6">
          <button
            className="btn btn-secondary w-100 text-white opacity-30"
            onClick={back}
            disabled={props.isDisabled}
          >
            {emailConfirmation.back}
          </button>
        </div>
        <div className="col-md-4 p-0">
          <button
            className="btn btn-primary w-100"
            onClick={props.mainAction}
            disabled={props.isDisabled}
          >
            {props.isDisabled ? <Spinner color="light" size="sm" /> : null}
            {"  "}
            {emailConfirmation.register}
          </button>
        </div>
      </div>
    </>
  );
}
