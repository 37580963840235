import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Locale from "../../../translations";

export default class ApexChart extends React.Component {

    constructor(props) {
        super(props);
        const {translate} = Locale;

        this.state = {

            num: props.num?.reservations,

            series: [props.num.finished, props.num.inprogress],
            options: {
                chart: {
                    type: 'donut',
                    height: 200,
                    width: '100%',
                },


                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],

                plotOptions: {
                    pie: {
                        startAngle: 10,
                        donut: {
                            size: '77%',
                            dataLabels: {
                                enabled: false
                            },
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    offsetY: 18,
                                    formatter: () => ` ${translate.reservationsTotal}   ${props?.num?.finished + props?.num?.inprogress}`
                                },
                                value: {
                                    // show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 500,
                                    color: '#fff',
                                    offsetY: -10
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    color: '#333',
                                    fontFamily: 'Cairo',
                                    fontWeight: 700,

                                }
                            }
                        }
                    },
                },
                //
                dataLabels: {
                    enabled: true, formatter: function (val, opt) {
                        return " "
                    }
                },
                stroke: {
                    width: 0
                },
                fill: {colors: ['#F28F31', '#24AEFC']},
                colors: ['#F28F31', '#24AEFC'],
                labels: [translate.InProgress, translate.AreChecked],
            }
        };
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={350}/>
            </div>
        );
    }
}
