import React, { useState } from "react";
import { useHistory } from "react-router";
import logoImg from "../../../assets/images/Image1.png";
import Checkbox from "../../../components/shared/checkbox";
import TextField from "../../../components/shared/textField";
import { useAdminDispatch } from "../../../context/Admin/global";
import { singin } from "../../../services/admin";
import Locale from "../../../translations";

export default function Login() {
  let history = useHistory();
  const [user, setUser] = useState({
    email: "",
    password: ""
  });
  const { translate } = Locale;
  const dispatch = useAdminDispatch();
  //const dispaaaaatch = useCompanyState();
  //console.log(dispaaaaatch);
  const login = async () => {
    const res = await singin(user);
    console.log(res);
    if (res.status === 200) {
      dispatch({ type: "login", payload: res.data.data });
      //window.location.reload();
      window.location.href = "/admin/home";
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <figure className="logo w-25">
        <img className="w-100" src={logoImg} alt="logo" />
      </figure>

      <div className="d-flex flex-column ml-5 w-25 ">
        <div className="mt-4">
          <TextField
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          >
            <i className="fas fa-user text-primary"></i>
          </TextField>
        </div>
        <div className="mt-4">
          <TextField
            value={user.password}
            type="password"
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          >
            <i className="fas fa-eye text-primary"></i>
          </TextField>
        </div>
        <div className="mt-4">
          <Checkbox
            hasLabel={true}
            className="radio_bnt"
            label={"Remember me"}
          />
        </div>
        <button className="btn btn-primary mt-4" onClick={login}>
          {translate.signIn}
        </button>
      </div>
    </div>
  );
}
