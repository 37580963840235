import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

// React Component
export default function AdminAuthRoute({ component: Component, ...props }) {
  const AdminIsAuth = localStorage.getItem("Admin_isAuth");
const location = useLocation();

  if (AdminIsAuth === 'true' && location.pathname ===  '/admin/login') {
    return <Redirect to="/admin/home" />;
  } else {
    return (
      <Route
        {...props}
        render={(matchProps) => (
          <Component {...matchProps} />
        )}
      />
    );
  }
}
