import axios from "axios";
import { cleanEmpty } from "./general";

const walletUrl = process.env.REACT_APP_API_URL + "/api/wallet";

export const getCurrentBalance = async () => {
  return await axios
    .get(`${walletUrl}/show`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const getWalletRequest = async (status , filter) => {
  return await axios
    .get(`${walletUrl}/request?status=${status}`,{
      params: cleanEmpty(filter),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const createWalletRequest = async (data) => {
  return await axios
    .post(`${walletUrl}/request`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const createWalletTransaction = async (amount) => {
  return await axios
    .post(`${walletUrl}/safa/sadad`, amount)
    .then((res) => res)
    .catch((err) => err.response);
};

export const WalletTransactionLogs = async (id , filter) => {
  return await axios
    .get(`${walletUrl}/transaction-logs?company_id=${id}`,{
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};
