import emailImg from "assets/images/mailbox.svg";
import axios from "axios";
import { useAuthState } from "context/auth";
import AuthLayout from "modules/Yarvel/auth/layout/AuthLayout";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Locale from "translations";
// import Locale from "./RegistrationSucceed.locale";
const { success, forgetPassword } = Locale;

export default function NotConfirmed(props) {
  // ----------------------------------------------------------------------------------------------------
  // Component States / Variables / Constants
  // ----------------------------------------------------------------------------------------------------
  const userEmail = useAuthState();
  const [counter, setCounter] = React.useState(60);
  const [isResend, setIsResend] = useState(true);
  const [email, setEmail] = useState(null);
  const [title, setTitle] = useState(success.successTitle);
  const [message, setMessage] = useState(success.successMessage);
  const { pathname, state } = useLocation();
  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (isResend) {
      const timer =
        counter > 0
          ? setInterval(() => setCounter(counter - 1), 1000)
          : setIsResend(false);
      return () => {
        clearInterval(timer);
      };
    }
  }, [counter, isResend]);

 

  // ----------------------------------------------------------------------------------------------------
  // Main Component Body UI
  // ----------------------------------------------------------------------------------------------------
  return (
    <>
      <AuthLayout>
        <div className="bg-white p-2 px-3 radius-10 mt-3">
          <div className="row">
            <div className="col-md-6 mx-auto text-center py-4">
              <img
                src={emailImg}
                width="180"
                alt="email-verification"
                title="email-verification"
              />
              <p className="text-headline font-medium">تم إنشاؤه بنجاح</p>
      
             
            </div>
          </div>
        </div>
        <div className="row mt-3 justify-content-between m-0">
          <div className="col-md-6" />
          <div className="col-md-4">
            <Link to="/login" className="btn btn-primary w-100">
              {forgetPassword.backLogin}
            </Link>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
