import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { NavLink, useParams } from "react-router-dom";
import { store } from "react-notifications-component";

import { useAdminState } from "../../../context/Admin/global";
import {
  listPermissionsDB,
  editGroupAPI,
  RetrievedGroup,
} from "../../../services/admin";
import Locale from "../../../translations";

const EditGroup = (props) => {
  const { translate } = Locale;
  const [permissionsState, setPermissionsState] = useState([]);
  const [haedOfpermissionsState, setHaedOfpermissionsState] = useState([]);
  const [permissionsEditState, setPermissionsEditState] = useState([]);
  const { currentLanguage } = useAdminState();
  const { group_id } = useParams();
  const [errorSubmit, setErrorSubmit] = useState(false);

  const [permissionChecked, setPermissionChecked] = useState({});

  const handleChange = (event) => {
    let checkedNow = {
      [event.target.name]: event.target.checked,
    };

    setPermissionChecked({
      ...permissionChecked,
      [event.target.id]: checkedNow,
    });
  };

  const handleChangeAll = (event, data, index) => {
    console.log("haedOfpermissionsState", haedOfpermissionsState);
    let newHaedOfpermissions = haedOfpermissionsState.slice();
    newHaedOfpermissions[index] = true;
    console.log("new HaedOfpermissions", newHaedOfpermissions);
    setHaedOfpermissionsState(newHaedOfpermissions);

    let childArr = [];
    let newArr = Object.values(Object.assign({}, permissionChecked));

    data.map((item) => {
      childArr.push(item.id);
    });

    for (let j = 0; j < newArr.length; j++) {
      childArr.map((item) => {
        if (Object.keys(newArr[j]).toString() === item.toString()) {
          newArr[j] = { [Object.keys(newArr[j]).toString()]: true };
        }
      });
    }

    setPermissionChecked(newArr);
  };

  useEffect(() => {
    async function fetchData() {
      const Group = await RetrievedGroup(group_id);

      formik.setFieldValue("groupName", Group.data.data.name);

      let permissionsEdit = Group.data.data.permissions;
      let entries_permissionsEdit = Object.values(permissionsEdit);

      entries_permissionsEdit.map((item) => {
        return item.map((child) => {
          let childArr = permissionsEditState;
          childArr.push(child.id);
          setPermissionsEditState(childArr);
          return childArr;
        });
      });

      const PermissionsDB = await listPermissionsDB();
      if (PermissionsDB.status === 200) {
        let permissionArr = Object.values(PermissionsDB.data);
        setPermissionsState(permissionArr);
        let objectChecked = {};
        let Arr = permissionArr.map((permission) => {
          return permission.map((item) => {
            return { ...objectChecked, [item.id]: false };
          });
        });
        convertArray(Arr);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log("permissionChecked - Now", permissionChecked);
  }, [permissionChecked]);

  const convertArray = (Arr) => {
    var newArr = [];

    for (var i = 0; i < Arr.length; i++) {
      newArr = newArr.concat(Arr[i]);
    }

    for (let j = 0; j < newArr.length; j++) {
      permissionsEditState.map((item) => {
        if (Object.keys(newArr[j]).toString() === item.toString()) {
          newArr[j] = { [Object.keys(newArr[j]).toString()]: true };
        }
      });
    }

    console.log("Final", newArr);

    setPermissionChecked(newArr);
    return newArr;
  };

  const editGroup = async (payload) => {
    const res = await editGroupAPI(group_id, payload);

    if (res.status === 201) {
      console.log("notifications");
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    console.log("res", res);
    props.history.push("/Admin/team-management/groups");
  };

  const validationSchema = yup.object({
    groupName: yup
      .string(translate.groupNameError)
      .required(translate.groupNameRequired),
  });

  const formik = useFormik({
    initialValues: {
      groupName: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let permissionSubmit = [];
      for (let key in permissionChecked) {
        let objNew = permissionChecked[key];
        for (let aKey in objNew) {
          if (objNew[aKey] === true) {
            permissionSubmit.push(aKey);
          }
        }
      }

      let payload = {
        name: values.groupName,
        type: localStorage.getItem("Admin_userType"),
        company_id: localStorage.getItem("Admin_companyId"),
        permissions: permissionSubmit,
      };
      console.log("payload", payload);

      if (permissionSubmit.length > 0) {
        editGroup(payload);
      } else {
        setErrorSubmit(true);
        console.log("not have permissionSubmit");
      }
    },
  });

  let count = 0;

  return (
    <section>
      <div className="container my-4 ">
        <header className=" AdminTeamlist_header border py-3 px-3">
          <div className="d-flex justify-content-between  ">
            <h3 className="text-muted font-weight-bold AdminTeamlist_title">
              {translate.editGroup}
            </h3>
          </div>
        </header>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-50 my-3">
            <TextField
              className={`WorkTeamInput ${
                formik.touched.groupName && formik.errors.groupName
                  ? "WorkTeamInputError"
                  : ""
              }`}
              id="groupName"
              name="groupName"
              label={translate.groupName}
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.groupName}
              onChange={formik.handleChange}
              error={
                formik.touched.groupName && Boolean(formik.errors.groupName)
              }
              helperText={formik.touched.groupName && formik.errors.groupName}
              placeholder={translate.groupName}
            />
          </div>

          <header className=" AdminTeamlist_header border py-3 px-3">
            <div className="d-flex justify-content-between  ">
              <h3 className="text-muted font-weight-bold AdminTeamlist_title">
                {translate.manageGroupPermissions}
              </h3>
            </div>
          </header>

          <div className="all-boxs">
            {permissionsState
              ? permissionsState.map((permission, index) => {
                  return (
                    <div key={permission[0].group_name} className="boxs">
                      <div className="box-header">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={haedOfpermissionsState[index]}
                              onChange={(event) => {
                                handleChangeAll(event, permission, index);
                              }}
                              name={permission[0].group_name}
                            />
                          }
                          label={permission[0].group_name}
                        />
                      </div>
                      <div className="box-body">
                        <FormControl component="fieldset">
                          <FormGroup>
                            {permission.map((item) => {
                              let CheckboxRender = (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        Object.values({
                                          ...permissionChecked[count],
                                        }).toString() === "false"
                                          ? false
                                          : true
                                      }
                                      onChange={handleChange}
                                      id={count}
                                      name={item.id}
                                    />
                                  }
                                  label={
                                    currentLanguage === "en"
                                      ? item.display_name.en
                                      : item.display_name.ar
                                  }
                                />
                              );
                              count++;
                              return CheckboxRender;
                            })}
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          {errorSubmit ? (
            <p className="GroupError">{translate.errorPermissions}</p>
          ) : null}

          <div className=" btns d-flex justify-content-between align align-items-end">
            <NavLink exact={true} to={`/Admin/team-management/groups`}>
              <button className="btn btn-secondary px-5 py-2 ">
                {translate.back}
              </button>
            </NavLink>
            <button className="btn btn-success px-5 py-2 " type="submit">
              {translate.edit}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditGroup;
