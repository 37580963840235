import "@fortawesome/fontawesome-free/css/all.min.css";
import Loader from "components/Loader";
import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import AdminAxiosConfig from "services/admin_axios-config";
import AdminLayout from "./components/layout/Admin";
import { useAdminState } from "./context/Admin/global";
import AdminRoutes from "./AdminRoutes";
import "./scss/index.scss";
import Locale from "./translations";
import { createBrowserHistory } from "history";

function App() {
  const { currentLanguage, AdminLoading } = useAdminState();

  const history = createBrowserHistory();
  console.log(history.location.pathname);

  AdminAxiosConfig();
  Locale.setLanguage(currentLanguage);
  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", currentLanguage === "ar" ? "rtl" : "ltr");

  return (
    <div
      style={{
        textAlign: currentLanguage === "ar" ? "right" : "left",
        fontFamily: currentLanguage === "ar" ? "Cairo" : "Roboto",
      }}
    >
      {AdminLoading && <Loader />}

      <ReactNotification />
      <Router>
        <AdminLayout>{AdminRoutes}</AdminLayout>
      </Router>
    </div>
  );
}

export default App;
