import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../../translations";

export default function OrderPending(props) {
	const { order, company_id } = props;
	const { translate_companies } = Locale;


	return (
    <>
      <tr>
        <td>{order.id}</td>
        <td>
          <p>{order.test_name}</p>
          <p>{order.grade_name}</p>
        </td>
        <td>{order.traveler_name}</td>
        <td>
          <p>{'Check-me'}</p>
          <p>{order.type}</p>
        </td>
        {/* <td>{order.type}</td> */}
        <td>{order.check_date}<p className="orders-check-time">{' ' + order.check_time}</p></td>
        {/* <td>{order['count-reservations']}</td> */}
        <td>{order.created_at}</td>
        <td>{order.total_cost}{translate_companies.l_e}</td>
        <td>{order.payment?.type === 'wallet'? translate_companies.wallet_asType : translate_companies.company_asType}</td>
        <td className="CompanyRow-last">
          <span className="CompanyRow-OrangeColor">{order.payment?.commission}{translate_companies.pounds}</span>
          <NavLink exact={true} to={`/admin/companies/orders/view/` + order.trip_id}>
            <i className="fa fa-angle-left CompanyRow-arrow" aria-hidden="true"></i>
          </NavLink>
        </td>
      </tr>
    </>
  );
}
