import Header from "components/layout";
import React from "react";
import RegistrationProgress from "../components/RegistrationProgress";

const RegistrationLayout = (props) => {
  return (
    <div className="h-100">
      <Header />
      <div
        className="row no-gutters align-items-center bg-gray-100 pt-5 py-5 registerFix-layout"
        {...props}
      >
        <div className="col-md-6 m-auto">
          <RegistrationProgress />
          <div className="mt-5">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationLayout;
