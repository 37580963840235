import logoImg from "assets/images/Image1.png";
import Checkbox from "components/shared/checkbox";
import TextField from "components/shared/textField";
import { useCompanyDispatch } from "context/global";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { singin } from "services/yarvel";
import Locale from "translations";

export default function Login() {
  let history = useHistory();
  const [user, setUser] = useState({
    email: "",
    password: ""
  });
  const { translate, emailConfirmation } = Locale;
  const dispatch = useCompanyDispatch();

  const login = async () => {
    const res = await singin(user);
debugger
    if (res.data?.data?.status === "rejected") {
      store.addNotification({
        title: `${translate.rejected}!`,
        message: `${res.data.data.rejection_reason || translate.rejected} ${
          translate.tryTo
        }`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true
        }
      });
    } else if (res.status === 200) {
      dispatch({ type: "login", payload: res.data.data });
      history.push("/");
    }else{
      debugger
      store.addNotification({
        title: `${translate.somethingWentWrong}!`,
        message: `${res.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true
        }
      });
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <figure className="logo w-25">
        <img className="w-100" src={logoImg} alt="logo" />
      </figure>

      <div className="d-flex flex-column ml-5 w-25 ">
        <div className="mt-4">
          <TextField
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          >
            <i className="fas fa-user text-primary"></i>
          </TextField>
        </div>
        <div className="mt-4">
          <TextField
            value={user.password}
            type="password"
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          >
            <i className="fas fa-eye text-primary"></i>
          </TextField>
        </div>
        <div className="mt-4">
          <Checkbox
            hasLabel={true}
            className="radio_bnt"
            label={translate.RememberMe}
          />
        </div>
        <button className="btn btn-primary mt-4" onClick={login}>
          {translate.signIn}
        </button>
        <Link to="/register" className="btn-link text-center mt-3">
          {emailConfirmation.createEmail}{" "}
        </Link>
      </div>
    </div>
  );
}
