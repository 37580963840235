import TextField from "components/shared/textField";
import useWallet from "modules/Yarvel/wallet/useWallet";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createWalletRequest } from "services/wallet";
import Locale from "translations";
import WalletLayout from "../WalletLayout";
import Card from "./Card";
import "./CreateRequest.scss";

export default function CreateRequest() {
  const { wallet } = Locale;
  const history = useHistory();
  const [amount, setAmount] = useState();
  const [image, setImage] = useState();

  const handleUpload = (e) => {
    const files = e.target.files;
    setImage(files[0]);
  };

  const addCredit = async () => {
    const formData = new FormData();
    formData.append("amount", amount);
    formData.append("photo", image);
    const res = await createWalletRequest(formData);
    if (res.status === 200) {
      history.push("/wallet/add/send", { info: res.data });

    }
  };

  return (
    <WalletLayout>
      <Card label={wallet.addBalanceRequest}>
        <TextField
          type="text"
          label={wallet.amount}
          placeholder="e.g 1000"
          hasLabel={true}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <div className="bg-white p-2 text-center  position-relative upload-images w-100 mt-3">
          <p className="text-left">{wallet.receiptUpload}</p>
          <p className="text-left">{image && image.name}</p>
          <button className="chose">{wallet.chooseImage}</button>
          <input
            onChange={handleUpload}
            type="file"
            id="uploadFile"
            accept="image/*"
          />
        </div>
        <button onClick={addCredit} className="add-credit mt-3 text-bold">
          {wallet.addBalance}
        </button>
      </Card>
    </WalletLayout>
  );
}
