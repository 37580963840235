import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_ADMIN_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

export const SafarnaURL = `https://${domain}/api`;

const URL = `http://${subdomain}.${domain}/api`;



export const getWalletRequest = async (status) => {
  return await axios
    .get(`${SafarnaURL}/wallet/request`,{
			params: cleanEmpty(status),
		})
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const getWalletRequestWithID = async (id) => {
  return await axios
    .get(`${SafarnaURL}/wallet/request/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const getWalletDeals = async (status) => {
  return await axios
    .get(`${SafarnaURL}/wallet/transaction-logs`,{
			params: cleanEmpty(status),
		})
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const getWalletDealsWithID = async (id) => {
  return await axios
    .get(`${SafarnaURL}/wallet/transaction?id=${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};


export const wallet_changeStatusRequest = async (id, status) => {
  return await axios
    .put(`${SafarnaURL}/wallet/request/${id}/${status}`)
    .then((res) => res)
    .catch((err) => err.response);
};