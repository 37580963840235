import React from "react";

import PCR from "./pcr";

export default function ListServices({ examnations, services }) {
  const allExamnations =
    examnations?.length > 0
      ? examnations.map((item, index) => {
          return <PCR examnation={item} key={index} services={services} />;
        })
      : [];

  return <div>{allExamnations}</div>;
}
