import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { store } from "react-notifications-component";
import Locale from "translations";
import Filter from "./Filter";
import useWallet from "./useWallet";
import "./wallet.scss";
import WalletLayout from "./WalletLayout";
import WalletRequests from "./WalletRequests";
import WalletDeals from "./WalletDeals";
import { company_getCurrentBalance, company_getWalletRequest, company_getWalletDeals, wallet_changeStatusRequest } from "../../../../services/admin";

export default function Wallet() {
  const { wallet, translate_companies } = Locale;

  const { company_id } = useParams();

  const [currentTab, setCurrentTab] = useState("requests");
  const [walletBalance, setWalletBalance] = useState([]); 
  const [walletRequests, setWalletRequests] = useState(); 
  const [walletDeals, setWalletDeals] = useState(); 
  const [typeWallet, setTypeWallet] = useState(''); 

  const ChangeStatusRequest = async (id, status) => {
    const res = await wallet_changeStatusRequest(id, status);
    if (res.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });

      const companyRequests = await company_getWalletRequest(company_id);
      setWalletRequests(companyRequests?.data);
      console.log(companyRequests);

      const companyBalance = await company_getCurrentBalance(company_id);
      setWalletBalance(companyBalance?.data);
      console.log(companyBalance?.data);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const companyBalance = await company_getCurrentBalance(company_id);
      setWalletBalance(companyBalance?.data);
      console.log(companyBalance?.data);

      // const companyRequests = await company_getWalletRequest(company_id);
      // setWalletRequests(companyRequests?.data);
      // console.log(companyRequests);

      // const companyDeals = await company_getWalletDeals(company_id);
      // setWalletDeals(companyDeals?.data);
      // console.log(companyDeals);
    }
    fetchData();
  }, []);

  return (
    <WalletLayout>
      <div className="container">
        <div className="row walletBalanceRow mb-5">
          {walletBalance ? walletBalance.map(wallet => (
            wallet.type === 'wallet'
              ? (<div className="walletBalanceContainer">
                  <h2 className="walletBalanceHeader">{translate_companies.company_account}</h2>
                  <div className="walletBalanceContent">
                    <div className="walletBalanceAmountContent">
                      <h2 className="walletBalanceAmountTitle">{translate_companies.current_balance}</h2>
                      <p className="walletBalanceAmountNumber">{wallet.amount}</p>
                      <span className="walletBalanceAmountPound">{translate_companies.egyptian_pound}</span>
                    </div>
                    <div className="walletBalanceFooter">
                      <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/add/${company_id}`}>
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        {translate_companies.add_credit}
                      </Link>
                    </div>
                  </div>
                </div>
              )
              : <div className="walletBalanceContainer">
                <h2 className="walletBalanceHeader">{translate_companies.safa_balance}</h2>
                <div className="walletBalanceContent">
                  <div className="walletBalanceAmountContent">
                    <h2 className="walletBalanceAmountTitle">{translate_companies.remaining_balance + '/' + translate_companies.total_balance}</h2>
                    <p className="walletBalanceAmountNumber">{wallet.limit + '/'}<span className="walletBalanceAmountNumberSpan">{wallet.amount}</span></p>
                    <span className="walletBalanceAmountPound">{translate_companies.egyptian_pound}</span>
                  </div>
                  <div className="walletBalanceDebtFooter">
                    <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/safa-add/${company_id}`}>
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      {translate_companies.add_credit}
                    </Link>

                    <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/safa-pay/${company_id}`}>
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      {translate_companies.pay_debt}
                    </Link>
                  </div>
                </div>
              </div>
          )
          ) : null}

          {(walletBalance.length === 1)
          ? (walletBalance[0].type === 'wallet' ? (
            (              <div className="walletBalanceContainer">
                <h2 className="walletBalanceHeader">{translate_companies.safa_balance}</h2>
                <div className="walletBalanceContent">
                  <div className="walletBalanceAmountContent">
                    <h2 className="walletBalanceAmountTitle">{translate_companies.remaining_balance + '/' + translate_companies.total_balance}</h2>
                    <p className="walletBalanceAmountNumber">{0 + '/'}<span className="walletBalanceAmountNumberSpan">{0}</span></p>
                    <span className="walletBalanceAmountPound">{translate_companies.egyptian_pound}</span>
                  </div>
                  <div className="walletBalanceDebtFooter">
                    <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/safa-add/${company_id}`}>
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      {translate_companies.add_credit}
                    </Link>

                    <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/safa-pay/${company_id}`}>
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      {translate_companies.pay_debt}
                    </Link>
                  </div>
                </div>
              </div>)
          )  : null) : null}

          {walletBalance.length === 0 ?
          (
            <React.Fragment>
            <div className="walletBalanceContainer">
                <h2 className="walletBalanceHeader">{translate_companies.company_account}</h2>
                <div className="walletBalanceContent">
                  <div className="walletBalanceAmountContent">
                    <h2 className="walletBalanceAmountTitle">{translate_companies.current_balance}</h2>
                    <p className="walletBalanceAmountNumber">{0}</p>
                    <span className="walletBalanceAmountPound">{translate_companies.egyptian_pound}</span>
                  </div>
                  <div className="walletBalanceFooter">
                    <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/add/${company_id}`}>
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      {translate_companies.add_credit}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="walletBalanceContainer">
                <h2 className="walletBalanceHeader">{translate_companies.safa_balance}</h2>
                <div className="walletBalanceContent">
                  <div className="walletBalanceAmountContent">
                    <h2 className="walletBalanceAmountTitle">{translate_companies.remaining_balance + '/' + translate_companies.total_balance}</h2>
                    <p className="walletBalanceAmountNumber">{0 + '/'}<span className="walletBalanceAmountNumberSpan">{0}</span></p>
                    <span className="walletBalanceAmountPound">{translate_companies.egyptian_pound}</span>
                  </div>
                  <div className="walletBalanceDebtFooter">
                    <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/safa-add/${company_id}`}>
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      {translate_companies.add_credit}
                    </Link>

                    <Link role="button" exact={true} className="walletBalanceAdd" to={`/admin/companies/wallet/safa-pay/${company_id}`}>
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      {translate_companies.pay_debt}
                    </Link>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
        <div className="row tabs">
          <button
            className={`tab ${currentTab === "deals" ? "active" : ""}`}
            onClick={() => setCurrentTab("deals")}
          >
            <i className="fas fa-exchange-alt fa-lg mx-2"></i>
            {wallet.deals}
          </button>
          <button
            className={`tab ${currentTab === "requests" ? "active" : ""}`}
            onClick={() => setCurrentTab("requests")}
          >
            <i className="fas fa-file-alt fa-lg mx-2"></i>
            {wallet.requests}
          </button>
        </div>
        <div className="row requests">
          {currentTab === "requests" && <WalletRequests changeStatusRequest={ChangeStatusRequest} companyID={company_id} />}
          {currentTab === "deals" && <WalletDeals companyID={company_id} />}
        </div>
      </div>
    </WalletLayout>
  );
}
