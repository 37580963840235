import React from "react";
import walletAdd from "assets/images/wallet-add.svg";
import Locale from "translations";

export default function Card({ children, label, type, walletBalance }) {
  const { translate_companies } = Locale;

  return (
    <div className="create-request">
      <div className="row justify-content-center">
        <div className="col-8 card WalletCard">
          <img src={walletAdd} alt="wallet icon" />
          <b className="my-2">{label}</b>
          {type === "pay"
            ? (<div className="walletPayAmountContainer">
              {walletBalance ? walletBalance.map(wallet => (
                wallet.type === 'wallet'
                  ? <div className="walletPayAmountContent">
                    <p className="walletPayAmountHeader">{translate_companies.wallet_company_account}</p>
                    <span className="walletPayAmountPound">{wallet.amount} {translate_companies.egyptian_pound}</span>
                  </div>
                  : <div className="walletPayAmountContent">
                    <p className="walletPayAmountHeader">{translate_companies.wallet_safa_account}</p>
                    <span className="walletPayAmountPound">{wallet.limit + '/' + wallet.amount} {translate_companies.egyptian_pound}</span>
                  </div>
              )
              ) : null}
            </div>)
            : null}
          {children}
        </div>
      </div>
    </div>
  );
}
