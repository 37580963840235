import React, { useState } from "react";
import Locale from "../../../translations";
import {  NavLink } from "react-router-dom";
import Orders from "./orders";

import { ListAccountsExport } from "../../../services/admin";

import ExcelIcon from '../../../assets/images/icons/excel.svg';

function Accounts() {
  const { Accounts } = Locale;

  const [filter, setFilter] = useState({
    company_name: '',
    check_type: '',
    payment_date: '',
    payment_method: '',
    page: 1,
  });

  const downloadExcel = async () => {
    await ListAccountsExport(filter);
  };

  return (
    <section>
      <div className="AdminContainer-custom container ">
        <header className="d-flex CompaniesList-orders py-3 px-2  ">
        <div>
          <h3 className="text-muted font-weight-bold CompaniesList-title">{Accounts.headTile}</h3>
        </div>
          <div>
            <div onClick={downloadExcel}
              className="text-bold mx-1 px-3 bg-brwon rounded text-muted CompaniesList-LinkExcel"
            >
              <img src={ExcelIcon} className="ExcelIcon" alt="ExcelIcon" />
              {Accounts.export_excel}
            </div>
          </div>
        </header>
        <Orders />
      </div>
    </section>
  );
}

export default Accounts;
