import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import React from "react";
import Locale from "translations";
import reset from "assets/images/reset.svg";

export default function Filter({
  filter,
  setfilter,
  sumbit,
  currentTab,
  setsubmiting,
  submiting,
}) {
  const { wallet, translate } = Locale;
  const paymentMethod = [
    { value: "add_credit", label: "اضافة رصيد يارفل" },
    { value: "deduct_credit", label: "خصم رصيد يارفل" },
    { value: "add_wallet", label: "اضافة رصيد محفظتي" },
    { value: "deduct_wallet", label: "خصم رصيد محفظتي" },
  ];

  const statues = [
    { value: "pending", label: "  قيد الانتظار" },
    { value: "rejected", label: "  رفض" },
    { value: "approved", label: " مؤكد " },
  ];

  return (
    <div className="row filter">
      <TextField
        type="text"
        value={filter.transaction_number}
        placeholder={"بحث"}
        onChange={(e) =>
          setfilter({
            transaction_number: e.target.value,
          })
        }
      />
      <DatePicker
        hasLabel={false}
        placeholder="DD/MM/YYY"
        date={filter.transaction_date}
        onChangeDate={(e) => {
          setfilter({
            transaction_date: e,
          });
        }}
      />
      {currentTab === "transfer" && (
        <SelectField
          hasLabel={false}
          value={filter.type}
          placeholder={translate.status}
          options={paymentMethod}
          onChange={(e) =>
            setfilter({
              type: e,
            })
          }
        />
      )}
      {currentTab === "not-approved" && (
        <SelectField
          hasLabel={false}
          value={filter.type}
          placeholder={translate.status}
          options={statues}
          onChange={(e) =>
            setfilter({
              type: e,
            })
          }
        />
      )}
      <button className="search" onClick={() => setsubmiting(!submiting)}>
        {translate.search}
      </button>
      <button
        className="reset"
        onClick={() => {
          setfilter({ transaction_number: "", transaction_date: "", type: "" });
          setsubmiting(!submiting);
        }}
      >
        <img src={reset} alt="reset icon" /> {translate.reset}
      </button>
    </div>
  );
}
