import React, { useMemo, useState } from "react";
import Locale from "translations";
import ClientPayment from "./clientPayment";

export default function ListPayment({
  reservation,
  setReservation,

}) {
  const { translate } = Locale;
  const [cost, setCost] = useState({
    totalCommission: 0,
  });
  const getTotalComission = () => {
    let x = 0;
    debugger
    if (reservation && reservation.length > 0) {
      reservation.forEach((element) => {
        if (element?.by?.value === "credit") {
          x = x + element?.credit_comission;
        } else if (element?.by?.value === "wallet") {
          x = x + element?.debit_comission;
        }
      });
    }
    return x;
  };

  let totalCost = 0;
  const allReservation =
    reservation.length > 0
      ? reservation.map((item, index) => {
          totalCost = totalCost + item.total_cost;

          return (
            <ClientPayment
              data={item}
              reservation={reservation}
              setReservation={setReservation}
              getTotalComission={getTotalComission}
              key={index}
              index={index}
              cost={cost}
              setCost={setCost}
            />
          );
        })
      : [];

  return (
    <div>
      {allReservation}.
      <div className="d-flex  justify-content-center  mt-4">
        <div className="mx-5">
          <p className="text-muted text-bolder h5">{translate.TotalCost}</p>
          <p className="text-bolder text-primary h5"> {totalCost}</p>
        </div>
        <div className="mx-5">
          <p className="text-muted text-bolder h5">
            {translate.TotalCommission}
          </p>
          <p className="text-bolder text-primary h5 ">{getTotalComission()}</p>
        </div>
      </div>
    </div>
  );
}
