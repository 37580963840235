import { useClientsDispatch, useClientsState } from "context/addClient";
import { useCompanyState } from "context/global";
import { de } from "date-fns/locale";
import { isFormValid } from "helpers/validate";
import moment from "moment";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getClientsReservation, updateTrip } from "services/yarvel";
import Locale from "translations";
import ListTravelers from "./listTravelers";
import TripInfo from "./tripInfo";
export default function TripsData({
  setErrorsTrip,
  setErrorsClients,
  errorsClients,
  checkFormallErrors,
  setSwitchTo,
  switchTo,
  doneCheck,
  setDoneCheck,
  errorsTrip,
}) {
  const { translate } = Locale;
  const { trip_id } = useParams();
  const { tripInfoState } = useClientsState();

  const { company_id } = useCompanyState();
  const [loading, setloading] = useState(false);
  const dispatch = useClientsDispatch();
  const [editing, setEditing] = useState();

  const { service } = useClientsState();

  /* get data in edit case */
  useEffect(() => {
    if (trip_id || switchTo.tripId != " ") {
      async function fetchListClients() {
        const list = await getClientsReservation(
          trip_id ? trip_id : switchTo.tripId
        );
        debugger;
        dispatch({
          type: "clients",
          payload: list?.data.data.travelers,
        });
        dispatch({
          type: "addTrip",
          data: list?.data.data.Trip,
        });

        dispatch({
          type: "addService",
          data: list?.data.data.service,
        });
        setSwitchTo({
          ...switchTo,
          tripId: list.data.data.Trip.id,
        });
        setloading(true);
      }
      fetchListClients();
    }
  }, [trip_id]);

  /* edit  trip */
  useEffect(() => {
    const editTrip = async () => {
      if (isFormValid(errorsTrip) && trip_id) {
        const resTrip = await updateTrip(trip_id, {
          ...tripInfoState,
          company_id: company_id,
        });
      }
    };
    editTrip();
  }, [editing]);

  return (
    <section className=" ">
      {service.check_id === 1 && (
        <TripInfo
          switchTo={switchTo}
          setErrorsTrip={setErrorsTrip}
          checkFormallErrors={checkFormallErrors}
          doneCheck={doneCheck}
          setDoneCheck={setDoneCheck}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    
      {trip_id || switchTo.tripId != " " ? (
        loading ? (
          <ListTravelers
            doneCheck={doneCheck}
            setDoneCheck={setDoneCheck}
            setErrorsClients={setErrorsClients}
            errorsClients={errorsClients}
            checkFormallErrors={checkFormallErrors}
            setSwitchTo={setSwitchTo}
            switchTo={switchTo}
          />
        ) : null
      ) : (
        <ListTravelers
          doneCheck={doneCheck}
          setDoneCheck={setDoneCheck}
          setErrorsClients={setErrorsClients}
          errorsClients={errorsClients}
          checkFormallErrors={checkFormallErrors}
          setSwitchTo={setSwitchTo}
          switchTo={switchTo}
        />
      )}
    </section>
  );
}
