import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../translations";

export default function Order(props) {
  const { order } = props;
  const { translate_companies } = Locale;
  console.log('Order', order);

  return (
    <>
      <tr>
        <td className={(order.result_time < order.time_taken) ? 'CompanyRow-first-RedColor' : 'CompanyRow-first-GreenColor'}>{order.company_name}</td>
        <td >{order.id}</td>
        <td>
          <p>{order.test_name}</p>
          <p>{order.grade_name}</p>
        </td>
        <td>{order.traveler_name}</td>
        <td>
          <p>{'Check-me'}</p>
          <p>{order.type}</p>
        </td>
        {/* <td>{order.type}</td> */}
        {/* <td>{order['count-reservations']}</td> */}
        <td>{order.created_at}</td>
        <td>{order.check_date}<p className="orders-check-time">{' ' + order.check_time}</p></td>
        {/* <td>{order.result_time}</td> */}
        <td>{order.total_cost}{translate_companies.l_e}</td>
        <td className={(order.result_time < order.time_taken) ? 'CompanyRow-RedColor' : 'CompanyRow-GreenColor'}>{order.time_taken}{order.time_taken > 10 ? translate_companies.hour : translate_companies.hours}</td>
        <td>{order.payment?.type === 'wallet' ? translate_companies.wallet_asType : translate_companies.company_asType}</td>
        <td className="CompanyRow-last">
          <span className="CompanyRow-OrangeColor">{order.payment?.commission}{translate_companies.pounds}</span>
          <NavLink exact={true} to={`/admin/medical-services-requests/view/` + order.trip_id}>
            <i className="fa fa-angle-left CompanyRow-arrow" aria-hidden="true"></i>
          </NavLink>
        </td>
      </tr>
    </>
  );
}
