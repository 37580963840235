import { LooksupServicesCities, LooksupTestTypes } from "components/lookups";
import SelectField from "components/shared/SelectField";
import React, { useEffect, useState } from "react";
import { fetchAreas, fetchServiceAreas } from "services/lookups";
import { getExamnation } from "services/yarvel";
import Locale from "translations";
import ListServices from "./listServices";

export default function MedicalService() {
  const { translate } = Locale;
  const locale = localStorage.getItem("currentLanguage") || "en";
  const [sumbting, setsumbting] = useState(false);
  const [examnations, setExamnations] = useState({});
  const [area, setArea] = useState([]);
  const [services, setServices] = useState({
    city_id: null,
    area_id: null,
    type_id: null,
  });

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setServices({ ...services, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      setServices({ ...services, [name]: value });
    }
  };

  const cities = LooksupServicesCities();
  const typies = LooksupTestTypes();

  /* fetch areas */
  useEffect(() => {
    async function fetchLookups() {
      if (services?.city_id?.value) {
        const res = await fetchServiceAreas(services.city_id?.value);
        debugger
        
          const formatted = res.areas.map((area) => ({
            value: area.id,
            label: area.name,
          }));
          setArea(formatted);
        
      }
    }
    fetchLookups();
  }, [services?.city_id?.value]);

  useEffect(() => {
    async function fitchExamnation() {
      if (services?.city_id?.value) {
        const res = await getExamnation({
          ...services,
          city_id: services?.city_id?.value,
          area_id: services?.area_id?.value,
          type_id: services?.type_id?.value,
        });
        if (res.status === 201 || res.status === 200) {
          setExamnations([...res.data.services]);
        }
      }
    }
    fitchExamnation();
  }, [sumbting]);

  return (
    <div className="">
      <div className="container">
        <div className="row align-items-center py-5">
          <div className="col-3">
            <SelectField
              hasLabel={true}
              label={translate.governorate}
              onFocus={true}
              placeholder={translate.city}
              options={cities}
              name="city_id"
              onChange={(e) => {
                handleChange({ name: "city_id", value: e });
              }}
            />
          </div>
          <div className="col-3">
            <SelectField
              hasLabel={true}
              label={translate.area}
              placeholder={translate.area}
              options={area}
              onFocus={true}
              name="area_id"
              onChange={(e) => {
                handleChange({ name: "area_id", value: e });
              }}
            />
          </div>
          <div className="col-3">
            <SelectField
              hasLabel={true}
              label={translate.serviceType}
              onFocus={true}
              options={typies}
              placeholder={translate.serviceType}
              name="type_id"
              onChange={(e) => {
                handleChange({ name: "type_id", value: e });
              }}
            />
          </div>

          <div className="col-2">
            <button
              type="button"
              className="h4 m-0 text-bold  p-3 px-3 rounded btn btn-primary"
              onClick={() => {
                setsumbting(!sumbting);
              }}
            >
              <i class="fas fa-search mx-2"></i>
              {translate.search}
            </button>
          </div>
        </div>

        {/* content here */}
        <ListServices examnations={examnations} services={services} />
      </div>
    </div>
  );
}
