import PasswordField from "components/shared/PasswordField";
import validate, { isFormValid } from "helpers/validate";
import AuthLayout from "modules/Yarvel/auth/layout/AuthLayout";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { passReset } from "services/auth";
import Locale from "translations";

export default function ResetPassword(props) {
  // ----------------------------------------------------------------------------------------------------
  // Component States / Variables / Constants
  // ----------------------------------------------------------------------------------------------------
  const { passwordReset, commons } = Locale;
  const history = useHistory();
  const [resetPasswordState, setResetPasswordState] = useState({
    password: "",
    passwordConfirmation: "",
    sucsess: false
  });
  const [spin, setSpin] = useState(false);
  let { email, token } = useParams();
  const [errors, setErrors] = useState({});

  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------
  const isPasswordMatch =
    resetPasswordState.passwordConfirmation === resetPasswordState.password;

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: "password", value: resetPasswordState.password },
        { required: true, password: true, min: 8 }
      ),
      ...validate(
        {
          name: "confirm",
          value: isPasswordMatch
        },
        { required: true, confirm: true }
      )
    });
  };
  // Reset Password
  const resetPassword = async () => {
    checkFormErrors();
    setSpin(true);
    let requestBody = {
      email: email,
      token: token,
      password: resetPasswordState.password,
      password_confirmation: resetPasswordState.passwordConfirmation
    };
    if (isFormValid(errors)) {
      const response = await passReset(requestBody);
      if (response.status === 200) {
        store.addNotification({
          title: commons.createdSuccessfully,
          message: passwordReset.resetedSuccsessfully,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true
          }
        });
        setTimeout(function () {
          history.push("/login");
        }, 3000);
      }
    }
    setSpin(false);
  };

  useEffect(() => {
    if (!isPasswordMatch) {
      setErrors({
        ...errors,
        ...validate(
          {
            name: "confirm",
            value: isPasswordMatch
          },
          {
            required: true,
            confirm: true
          }
        )
      });
    } else {
      setErrors({
        ...errors,
        ...validate(
          {
            name: "confirm",
            value: isPasswordMatch
          },
          {
            required: false,
            confirm: false
          }
        )
      });
    }
  }, [resetPasswordState.password, resetPasswordState.passwordConfirmation]);
  // ----------------------------------------------------------------------------------------------------
  // Component UI
  // ----------------------------------------------------------------------------------------------------
  return (
    <>
      <AuthLayout>
        <h5 className="text-title text-center">
          {passwordReset.resetPassword}
        </h5>
        <p className="text-headline text-center">
          {passwordReset.ResetMessage}
        </p>
        <div className="reset-form bg-white px-2 border rounded my-3">
          <PasswordField
            label={passwordReset.newPassword}
            placeholder={passwordReset.newPasswordPlaceholder}
            name="password"
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "password", value: e.target.value },
                  {
                    required: true,
                    password: true,
                    min: 8
                  }
                )
              });
              setResetPasswordState({
                ...resetPasswordState,
                password: e.target.value
              });
            }}
            min={8}
            color={
              errors?.password?.required ||
              errors?.password?.password ||
              errors?.password?.min
                ? "danger"
                : ""
            }
            errors={errors?.password}
          />

          <PasswordField
            label={passwordReset.repeatPassword}
            placeholder={passwordReset.repeatPasswordPlaceholder}
            name="confirm"
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "confirm", value: !isPasswordMatch },
                  {
                    required: true,
                    confirm: true
                  }
                )
              });
              setResetPasswordState({
                ...resetPasswordState,
                passwordConfirmation: e.target.value
              });
            }}
            min={8}
            color={
              errors?.confirm?.required || errors?.confirm?.confirm
                ? "danger"
                : ""
            }
            errors={errors?.confirm}
          />
        </div>
        <div className="col-6 offset-6 p-0">
          <button
            className="w-100 btn btn-primary"
            onClick={resetPassword}
            disabled={resetPasswordState.spin}
          >
            {spin ? <Spinner color="light" size="sm" /> : null}{" "}
            {passwordReset.submit}
          </button>
        </div>
      </AuthLayout>
    </>
  );
}
