import { useClientsDispatch, useClientsState } from "context/addClient";
import { isFormValid } from "helpers/validate";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { updateClients } from "services/yarvel";
import Locale from "translations";
import Travelers from "./travelers";

export default function ListTravelers({
  setErrorsClients,
  errorsClients,
  checkFormallErrors,
  setDoneCheck,
  doneCheck,
  setSwitchTo,
  switchTo,

}) {
  const { translate } = Locale;
  const dispatch = useClientsDispatch();
  const { listClients } = useClientsState();
  const { clientsNumber } = useParams();
  const { trip_id } = useParams();
  useEffect(() => {
    if (clientsNumber) {
      dispatch({
        type: "clientsNumber",
        count: clientsNumber ? clientsNumber : 1,
      });
    }
  }, []);

  console.log('jj');

  console.log(listClients);

  const allListClients =
    listClients.length > 0
      ? listClients.map((item, index) => (
          <Travelers
            data={item}
            setDoneCheck={setDoneCheck}
            doneCheck={doneCheck}
            setErrorsClients={setErrorsClients}
            errorsClients={errorsClients}
            key={index}
            checkFormallErrors={checkFormallErrors}
            index={index}
            setSwitchTo={setSwitchTo}
            switchTo={switchTo}
          />
        ))
      : [];

  return (
    <div className="pb-5">
      {allListClients }
      {!trip_id && switchTo.tripId === " " && (
        <button
          className="text-success font-weight-bold pt-3 d-flex align-items-baseline ml-5 p-0"
          onClick={() => {
            dispatch({
              type: "addClient",
            });
          }}
        >
          <i className="fas fa-plus-circle icons_label text-success m-3 "></i>
          <p className="h3 text-bold">{translate.AddTravele}</p>
        </button>
      )}
    </div>
  );
}
