import React from "react";
import { useHistory } from "react-router-dom";

import { NavLink } from "react-router-dom";
import AllCompanies from "./Companies";

import Locale from "../../../translations";

const Companies = (props) => {
  const { translate, Admin } = Locale;
  let history = useHistory();

  return (
    <section>
      <div className="AdminContainer-custom container ">
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2 CompanyListHeader">
          <h3 className="text-muted font-weight-bold CompanyListHeader-title">
            {Admin.active_company}
          </h3>
        </header>
        <AllCompanies />
      </div>
    </section>
  );
};

export default Companies;
