import React from 'react'
import Locale from 'translations';

export default function WalletTransfer({ requests }) {
      const { wallet, translate } = Locale;

  return (
    <>
      <table className="table">
        <thead className="">
          <tr className="table_head">
            <th scope="col">{wallet.receiptNumber}</th>
            <th scope="col">{wallet.requestDate}</th>
            <th scope="col">{wallet.amount}</th>
            <th scope="col">نوع المعامله</th>
            <th scope="col">{translate.processes}</th>
          </tr>
        </thead>
        <tbody>
          {requests && requests.length > 0 ? (
            requests.map((request) => (
              <tr key={request.id}>
                <td>{request.transaction_number}</td>
                <td>{request.transaction_date}</td>
                <td>{request.amount}</td>
                <td>{request.type}</td>
                <td className="d-flex">
                  {/* <button
                    className="p-0 btn btn-primary d-flex align-items-center  justify-content-center p-1 "
                    onClick={() => {
                      history.push("/view-result");
                    }}
                  >
                    <i class="fas fa-eye mx-2 "></i>
                    <p className="m-0">{translate.view}</p>
                  </button>

                  <button
                    className="p-0 btn btn-primary d-flex align-items-center  justify-content-center p-1 mx-2"
                    onClick={() => {
                      history.push(`/Add-Reservation/${client.trip_id}`);
                    }}
                  >
                    <i class="fas fa-edit mx-2"></i>
                    <p className="m-0">{translate.edit}</p>
                  </button> */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11">
                <div className=" text-center">
                  <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                  <h4 className=" text-muted">{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
