import React from 'react'

export default function Checkbox(props) {
  return (
    <div className="d-flex align-items-center">
      <input
        disabled={props.disabled}
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={props.checked}
        change={props.change}
        onChange={props.onChange}
        id={props.id}
        className={props.className}
      />

      <label
        className={` h4 mx-2 m-0 ${
          props.checked ? "text-primary" : "text-secondary"
        }`}
        htmlFor={props.id}
      >
        {props.label}
      </label>
    </div>
  );
}
