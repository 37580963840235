import { useClientsState } from "context/addClient";
import { useCompanyState } from "context/global";
import { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import {
  addClients,
  addClientsWithpcr,
  saveReservation,
} from "services/yarvel";
import Locale from "translations";
import Payment from "./payment";
import PcrReservation from "./PcrReservations";
import TripsData from "./TripsData";


export default function AddReservation() {
  const history = useHistory();

  const { translate } = Locale;
  const { company_id } = useCompanyState();
  const { tripInfoState } = useClientsState();
  const { listReservation } = useClientsState();
  const { listClients } = useClientsState();
  const { service } = useClientsState();
  const { test_type } = useParams();
  const { trip_id } = useParams();
  const [doneCheck, setDoneCheck] = useState(false);
  const [doneReservation, setDoneReservation] = useState(false);
  const [errorsClients, setErrorsClients] = useState([]);
  const [errorsTrip, setErrorsTrip] = useState([]);
  const [errorsReservation, setErrorsReservation] = useState([]);
  const [checked, setchecked] = useState(false);
  const [checkedReservation, setcheckedReservation] = useState(false);
  const [switchTo, setSwitchTo] = useState({
    name: "trips-data",
    tripId: " ",
  });

  const checkFormallErrors = () => {
    setchecked(!checked);
  };

  const checkFormallErrorsReservation = () => {
    setcheckedReservation(!checkedReservation);
  };

  // call api for add trip and clients pcr and others
  const saveClients = async () => {
    const flag = errorsClients.every((error) => {
      if (!isFormValid(error)) {
        return false;
      }
      return true;
    });
    let res;

    if (service.check_id != 1) {
      if (flag && switchTo.tripId == " " && errorsClients.length != 0) {
        res = await addClients({
          travelers: [...listClients],

          test_id: service.check_id,
          city_id: service.city_id.value,
          area_id: service.area_id.value,
          company_id: company_id,
          total_cost: service.price,
          result_time: service.time,
          grade_id: service.check_grade_id,
          credit_comission: service.credit_comission,
          debit_comission: service.debit_comission,
          type_id: service?.type_id?.value,
          service_id: service.id,
          safa_commission: service.safa_comission,
          comission_type: service.comission_type,
        });
      }
    }
    if (
      service.check_id === 1 &&
      isFormValid(errorsTrip) &&
      flag &&
      switchTo.tripId == " " &&
      errorsClients.length != 0
    ) {
      res = await addClientsWithpcr({
        ...tripInfoState,
        travelers: [...listClients],
        city_id: service.city_id.value,
        area_id: service.area_id.value,
        company_id: company_id,
        test_id: service.check_id,
        company_id: company_id,
        total_cost: service.price,
        result_time: service.time,
        grade_id: service.check_grade_id,
        credit_comission: service.credit_comission,
        debit_comission: service.debit_comission,
        type_id: service?.type_id?.value,
        service_id: service.id,
        safa_commission: service.safa_comission,
        comission_type: service.comission_type,
      });
    }
    debugger;
    if (res?.status === 201 || res?.status === 200) {
      setSwitchTo({
        ...switchTo,
        name: "pcr-reservation",
        tripId: res.data.data.Trip.id,
      });
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };

  // call api for save reservations
  const saveReservations = async () => {
    const flag = errorsReservation.every((error) => {
      if (!isFormValid(error)) {
        return false;
      }
      return true;
    });

    if (flag && errorsReservation.length != 0) {
      debugger;
      const res = await saveReservation({ reservations: [...listReservation] });

      if (res?.status === 201 || res?.status === 200) {
        setSwitchTo({
          ...switchTo,
          name: "payment",
        });
        /*     store.addNotification({
          title: "info!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        }); */
      }
    }
  };
  useEffect(() => {
    saveClients();
  }, [doneCheck]);

  useEffect(() => {
    saveReservations();
  }, [doneReservation]);

  return (
    <section className=" my-5 ">
      <div className="container  ">
        <header className=" border list_header  py-3 px-2 d-flex justify-content-between">
          <h3 className="text-muted font-weight-bold">{translate.Customers}</h3>
          {/* Actions to switch from component to other */}
          <div>
            <button
              className={`btn mr-3 bg-brwon  px-5  font-weight-bold ${
                switchTo.name === "trips-data"
                  ? "bg-primary text-white"
                  : "text-muted"
              }`}
              onClick={() => {
                setSwitchTo({
                  ...switchTo,
                  name: "trips-data",
                });
              }}
            >
              {translate.Customers}
            </button>
            <button
              disabled={switchTo.tripId === " "}
              className={`btn mr-3 bg-brwon px-5  font-weight-bold ${
                switchTo.name === "pcr-reservation"
                  ? "bg-primary text-white"
                  : "text-muted "
              }`}
              onClick={() => {
                setSwitchTo({
                  ...switchTo,
                  name: "pcr-reservation",
                });
              }}
            >
              {translate.pcr}
            </button>
            <button
              disabled={switchTo.tripId === " "}
              className={`btn mr-3 bg-brwon px-5  font-weight-bold ${
                switchTo.name === "payment"
                  ? "bg-primary text-white"
                  : "text-muted "
              }`}
              onClick={() => {
                setSwitchTo({
                  ...switchTo,
                  name: "payment",
                });
              }}
            >
              {translate.SendReservation}
            </button>
          </div>
        </header>

        {/* service header and content */}
        <div className="bg-white border ">
          {switchTo.name != "payment" && (
            <div className="d-flex list_content mb-3">
              <div className="d-flex medical_box p-3 mx-3 border rounded-lg  justify-content-between mt-4 w-100  ">
                <div className="h4 text-muted">
                  {translate.serviceType} :
                  <span className="text-dark"> {service.type}</span>
                </div>
                <div className="h4 text-muted">
                  <i class="far fa-star mx-2"></i>
                  {translate.ServiceGrade} :{" "}
                  <span className="text-dark">{service.grade}</span>
                </div>
                <div className="h4 text-muted">
                  <i class="far fa-clock mx-2"></i>
                  {translate.ResultTime} :{" "}
                  <span className="text-dark">{service.time}</span>
                </div>
                <div className="h4 text-muted">
                  <i class="fas fa-tag  mx-2"></i>
                  {translate.Priceperson} :{" "}
                  <span className="text-success">{service.price}</span>
                </div>
              </div>
            </div>
          )}

          {/* content here  */}

          {switchTo.name === "trips-data" && (
            <TripsData
              setErrorsClients={setErrorsClients}
              errorsClients={errorsClients}
              checkFormallErrors={checked}
              doneCheck={doneCheck}
              setDoneCheck={setDoneCheck}
              errorsTrip={errorsTrip}
              setErrorsTrip={setErrorsTrip}
              setSwitchTo={setSwitchTo}
              switchTo={switchTo}
            />
          )}

          {switchTo.name === "pcr-reservation" && (
            <PcrReservation
              switchTo={switchTo}
              setSwitchTo={setSwitchTo}
              errorsReservation={errorsReservation}
              setErrorsReservation={setErrorsReservation}
              service={service}
              checkFormallErrorsReservation={checkedReservation}
              doneReservation={doneReservation}
              setDoneReservation={setDoneReservation}
            />
          )}

          {switchTo.name === "payment" && (
            <Payment
              switchTo={switchTo}
              setSwitchTo={setSwitchTo}
              service={service}
            />
          )}
        </div>
        {/* Actions save and send appointment */}
        <div className=" py-3 d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary  px-5  font-weight-bold"
            onClick={() => {
              history.push("/customers");
            }}
          >
            {translate.back}
          </button>

          {switchTo.name === "pcr-reservation" && (
            <button
              type="button"
              className="btn btn-success px-5  font-weight-bold"
              onClick={() => {
                checkFormallErrorsReservation();
              }}
            >
              {translate.BookingAppointment}
            </button>
          )}

          {switchTo.name === "trips-data" &&
            !trip_id &&
            switchTo.tripId === " " && (
              <button
                type="button"
                className="btn btn-success px-5  font-weight-bold"
                onClick={() => {
                  checkFormallErrors();
                }}
              >
                {translate.SaveChanges}
              </button>
            )}
        </div>
      </div>
    </section>
  );
}
