import { LooksupCities, LooksupCountries } from "components/lookups";
import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import { useClientsDispatch, useClientsState } from "context/addClient";
import { useCompanyState } from "context/global";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
import {
  deletClient,
  getClientsReservation,
  getMRZ,
  updateClients,
} from "services/yarvel";
import { fetchAreas } from "services/lookups";
import { cleanEmpty } from "services/general";
import { cleanKeys } from "helpers/cleanKeys";


export default function Travelers({
  data,
  index,
  setErrorsClients,
  errorsClients,
  checkFormallErrors,
  setDoneCheck,
  doneCheck,
  setSwitchTo,
  switchTo,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const locale = localStorage.getItem("currentLanguage") || "en";
  const dispatch = useClientsDispatch();
  const toggle = () => setIsOpen(!isOpen);
  const { translate, userDetails } = Locale;
  const { currentLanguage } = useCompanyState();
  const { trip_id } = useParams();
  const cities = LooksupCities();
  const countries = LooksupCountries();
  const { test_type } = useParams();
  const [area, setArea] = useState([]);
  const { listClients } = useClientsState();
  const [clientData, setclientData] = useState({
    en_first_name: "",
    ar_first_name: "",
    en_father_name: "",
    ar_father_name: "",
    en_grandpa_name: "",
    ar_grandpa_name: "",
    en_last_name: "",
    ar_last_name: "",
    passport_number: "",
    expiration_date: null,
    nationality: "",
    country_id: "20",
    test_type: test_type,
    national_id: "",
    index: index,
  });
  const [startVaild, setstartVaild] = useState(false);
  const [checkedEdit, setcheckedEdit] = useState(false);
  const [startVaildEdit, setstartVaildEdit] = useState(false);
  const [editing, setEditing] = useState(false);
  const currentDate = moment(new Date());
  const limit = moment(currentDate).add(1, "M");

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setclientData({ ...clientData, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      setclientData({ ...clientData, [name]: value });
    }
  };

  /*   to  make edit in Travelers and switch Tap*/
  useEffect(() => {
    debugger;
    if (data && data.ar_first_name && switchTo.tripId != " ") {
      debugger;
      setclientData({
        ...cleanKeys(data),
        expiration_date: data.expiration_date
          ? moment(data.expiration_date)
          : "",
        /*     nationality: { value: data.nationality , }, */
      });
    } else {
      debugger;
      if (
        data.hasOwnProperty("ar_first_name") &&
        data &&
        clientData.index != data.index
      ) {
        setclientData({
          ...clientData,
          en_first_name: data.en_first_name,
          ar_first_name: data.ar_first_name,
          en_father_name: data.en_father_name,
          ar_father_name: data.ar_father_name,
          en_grandpa_name: data.en_grandpa_name,
          ar_grandpa_name: data.ar_grandpa_name,
          en_last_name: data.en_last_name,
          ar_last_name: data.ar_last_name,
          passport_number: data.passport_number,
          nationality: data.nationality,
          national_id: data.national_id,
          phone: data.phone,
          expiration_date: data.expiration_date
            ? moment(data.expiration_date)
            : "",
          country_id: "20",
          address: data.address,
          test_type: test_type,
          index: index,
        });
      }
    }
  }, []);

  /* sending data to clients state */
  useEffect(() => {
    const sendData = setTimeout(() => {
      dispatch({
        type: "addClientData",
        payload: {
          ...clientData,
          expiration_date: clientData?.expiration_date
            ? moment(clientData?.expiration_date).format("YYYY-MM-DD ")
            : "",
          nationality: clientData?.nationality?.value,
        },
        index: index,
      });
    }, 1000);

    return () => {
      clearTimeout(sendData);
    };
  }, [clientData]);

  /* check errors */
  const checkFormErrorsfileds = () => {
    let error = errorsClients;
    let newError = {
      ...validate(
        {
          name: "en_first_name",
          value: clientData.en_first_name,
        },
        {
          required: true,
          notNumber: true,
        }
      ),

      /*     ...validate(
        {
          name: "national_id",
          value: clientData.national_id,
        },
        {
          number: true,
        }
      ), */

      ...validate(
        {
          name: "en_father_name",
          value: clientData.en_father_name,
        },
        {
          notNumber: true,
        }
      ),
      ...validate(
        {
          name: "en_grandpa_name",
          value: clientData.en_grandpa_name,
        },
        {
          notNumber: true,
        }
      ),
      ...validate(
        {
          name: "en_last_name",
          value: clientData.en_last_name,
        },
        {
          required: true,
          notNumber: true,
        }
      ),
      ...validate(
        {
          name: "ar_first_name",
          value: clientData.ar_first_name,
        },
        {
          required: true,
          notNumber: true,
        }
      ),
      ...validate(
        {
          name: "ar_father_name",
          value: clientData.ar_father_name,
        },
        {
          notNumber: true,
        }
      ),
      ...validate(
        {
          name: "ar_grandpa_name",
          value: clientData.ar_grandpa_name,
        },
        {
          notNumber: true,
        }
      ),
      ...validate(
        {
          name: "ar_last_name",
          value: clientData.ar_last_name,
        },
        {
          required: true,
          notNumber: true,
        }
      ),
      ...validate(
        {
          name: "passport_number",
          value: clientData.passport_number,
        },
        {
          required: true,
        }
      ),
      /*       ...validate(
        {
          name: "expiration_date",
          value: clientData.expiration_date,
        },
        {
          required: true,
        }
      ), */
      /*     ...validate(
        {
          name: "phone",
          value: clientData.phone,
        },
        {
          required: true,
        }
      ), */

      /*       ...validate(
        {
          name: "address",
          value: clientData.address,
        },
        {
          required: true,
        }
      ), */
    };
    if (error[index]) {
      error[index] = {
        ...error[index],
        ...newError,
      };
    } else {
      error.splice(index, 0, newError);
    }
    setErrorsClients(error);
  };

  /* check errors to save clients */
  useEffect(() => {
    if (startVaild) {
      checkFormErrorsfileds();
      setDoneCheck(!doneCheck);
    }
    setstartVaild(true);
  }, [checkFormallErrors]);

  const setErrorsClientsfiled = (name, value, role) => {
    let error = errorsClients;
    error[index] = {
      ...error[index],
      ...validate({ name: name, value: value }, role),
    };
    setErrorsClients(error);
  };

  /* edit  Clients FLOW */
  const submitEdit = () => {
    debugger
    setcheckedEdit(!checkedEdit);
    setstartVaildEdit(true);
  };

  useEffect(() => {
    debugger
    if (startVaildEdit) {
      checkFormErrorsfileds();
      setEditing(true);
    }
    setstartVaildEdit(false);
  }, [checkedEdit]);

  useEffect(() => {
    debugger
    const editClients = async (id) => {
      if (isFormValid(errorsClients[index]) && editing) {
        const resClients = await updateClients(
          id,
          cleanEmpty({
            ...clientData,
            expiration_date: clientData.expiration_date
              ? moment(clientData?.expiration_date).format("YYYY-MM-DD")
              : null,
            country_id: 20,
            nationality: clientData.nationality
              ? clientData.nationality.value
              : "",
            national_id: clientData?.national_id,
            trip_id: trip_id,
          })
        );
      setErrorsClients({})

      }
    };
    editClients(data.id);
  }, [editing]);

  /* delet in edit */
  const removeClient = async (id) => {
    const remove = await deletClient(id);
    if (remove.status === 201) {
      debugger;
      const list = await getClientsReservation(
        trip_id ? trip_id : switchTo.tripId
      );
      if (list.status === 201 || list.status === 200) {
        dispatch({
          type: "clients",
          payload: list?.data.data.travelers,
        });

        dispatch({
          type: "removeReservation",
        });
      }
    }
  };

  /* MRZ FLOW */
  const mrz = (id) => {
    const res = getMRZ({
      mrz: id,
    });
  };

  return doneCheck || !doneCheck ? (
    <div className="mx-4 shadow mt-4  ">
      <div className="list_header d-flex justify-content-between align-items-center m-0 p-3 rounded-top ">
        <div className="d-flex">
          <button onClick={toggle} className="rounded-top ">
            <p className="m-0 h4  font-weight-bold text-color">
              {`${translate.client} (${index + 1}) 
            ${
              currentLanguage === "ar"
                ? `${clientData?.ar_first_name}  ${
                    clientData?.ar_father_name != null
                      ? clientData?.ar_father_name
                      : ""
                  }  ${
                    clientData?.ar_grandpa_name != null
                      ? clientData?.ar_grandpa_name
                      : ""
                  } ${clientData?.ar_last_name}`
                : `${clientData?.en_first_name}    ${
                    clientData?.en_father_name != null
                      ? clientData?.ar_father_name
                      : ""
                  }  ${
                    clientData?.en_grandpa_name != null
                      ? clientData?.ar_grandpa_name
                      : ""
                  }  ${clientData?.en_last_name} `
            }
          `}
            </p>
          </button>
        </div>

        <div onPaste={mrz} className="d-flex align-items-center ">
          <div className="bg-white p-0 btn mr-3 d-flex align-items-center">
            <h5 className="m-0 mx-3 text-muted">{translate.PassporAuto}</h5>
            <i className="fas fa-qrcode icons_label mx-1 p-1"></i>
          </div>
          {isOpen ? (
            <i className="fas fa-angle-down icons_label"></i>
          ) : currentLanguage === "ar" ? (
            <i className="fas fa-angle-left  icons_label"></i>
          ) : (
            <i className="fas fa-angle-right  icons_label"></i>
          )}
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        <Card className="py-4 px-5">
          <CardBody>
            <div className="row">
              <div className="col-3">
                <TextField
                  disabled={clientData.payment_status === "مدفوع"}
                  hasLabel={true}
                  label={"First Name"}
                  value={clientData?.en_first_name}
                  placeholder={"Enter First Name"}
                  name="en_first_name"
                  min={3}
                  errors={errorsClients[index]?.en_first_name}
                  color={
                    errorsClients[index]?.en_first_name?.required ||
                    errorsClients[index]?.en_first_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      en_first_name: e.target.value,
                    });
                    setErrorsClientsfiled("en_first_name", e.target.value, {
                      required: true,
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3">
                <TextField
                  disabled={clientData.payment_status === "مدفوع"}
                  hasLabel={true}
                  value={clientData?.en_father_name}
                  label={"Father Name"}
                  placeholder={"Enter Father Name"}
                  name="en_father_name"
                  min={3}
                  errors={errorsClients[index]?.en_father_name}
                  color={
                    errorsClients[index]?.en_father_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      en_father_name: e.target.value,
                    });
                    setErrorsClientsfiled("en_father_name", e.target.value, {
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3">
                <TextField
                  disabled={clientData.payment_status === "مدفوع"}
                  hasLabel={true}
                  value={clientData?.en_grandpa_name}
                  label={"Grand Name "}
                  placeholder={"Enter Grand Name"}
                  name="en_grandpa_name"
                  min={3}
                  errors={errorsClients[index]?.en_grandpa_name}
                  color={
                    errorsClients[index]?.en_grandpa_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      en_grandpa_name: e.target.value,
                    });
                    setErrorsClientsfiled("en_grandpa_name", e.target.value, {
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3">
                <TextField
                  disabled={clientData.payment_status === "مدفوع"}
                  hasLabel={true}
                  value={clientData?.en_last_name}
                  label={"Last Name "}
                  placeholder={"Enter Last Name"}
                  name="en_last_name"
                  min={3}
                  errors={errorsClients[index]?.en_last_name}
                  color={
                    errorsClients[index]?.en_last_name?.required ||
                    errorsClients[index]?.en_last_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      en_last_name: e.target.value,
                    });
                    setErrorsClientsfiled("en_last_name", e.target.value, {
                      required: true,
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3 mt-2">
                <TextField
                  disabled={clientData.payment_status === "مدفوع"}
                  hasLabel={true}
                  value={clientData?.ar_first_name}
                  label={"الاسم الاول "}
                  placeholder={"ادخل الاسم الاول"}
                  name="ar_first_name"
                  min={3}
                  errors={errorsClients[index]?.ar_first_name}
                  color={
                    errorsClients[index]?.ar_first_name?.required ||
                    errorsClients[index]?.ar_first_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      ar_first_name: e.target.value,
                    });
                    setErrorsClientsfiled("ar_first_name", e.target.value, {
                      required: true,
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3 mt-2">
                <TextField
                  hasLabel={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  value={clientData?.ar_father_name}
                  label={"اسم الآب "}
                  placeholder={"ادخل الاسم الآب"}
                  name="ar_father_name"
                  min={3}
                  errors={errorsClients[index]?.ar_father_name}
                  color={
                    errorsClients[index]?.ar_father_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      ar_father_name: e.target.value,
                    });
                    setErrorsClientsfiled("ar_father_name", e.target.value, {
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3 mt-2">
                <TextField
                  hasLabel={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  value={clientData?.ar_grandpa_name}
                  label={"اسم الجد   "}
                  placeholder={"ادخل الاسم الجد"}
                  name="ar_grandpa_name"
                  min={3}
                  errors={errorsClients[index]?.ar_grandpa_name}
                  color={
                    errorsClients[index]?.ar_grandpa_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      ar_grandpa_name: e.target.value,
                    });
                    setErrorsClientsfiled("ar_grandpa_name", e.target.value, {
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3 mt-2">
                <TextField
                  hasLabel={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  value={clientData?.ar_last_name}
                  label={"اللقب "}
                  placeholder={"ادخل الاسم اللقب"}
                  name="ar_last_name"
                  min={3}
                  errors={errorsClients[index]?.ar_last_name}
                  color={
                    errorsClients[index]?.ar_last_name?.required ||
                    errorsClients[index]?.ar_last_name?.notNumber
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      ar_last_name: e.target.value,
                    });
                    setErrorsClientsfiled("ar_last_name", e.target.value, {
                      required: true,
                      notNumber: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3 mt-2">
                <TextField
                  hasLabel={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  value={clientData?.passport_number}
                  label={translate.passportNumber}
                  name="passport_number"
                  min={3}
                  placeholder={translate.passportNumberPlaceHolder}
                  errors={errorsClients[index]?.passport_number}
                  color={
                    errorsClients[index]?.passport_number?.required ||
                    errorsClients[index]?.passport_number?.number
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      passport_number: e.target.value,
                    });
                    setErrorsClientsfiled("passport_number", e.target.value, {
                      required: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3 mt-2">
                <DatePicker
                  hasLabel={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  label={translate.ExpiryDate}
                  date={clientData?.expiration_date}
                  name="expiration_date"
                  placeholder={"10-10-201"}
                  displayFormat={"YYYY-MM-DD"}
                  minDate={limit}
                  /*                   errors={errorsClients[index]?.expiration_date} */
                  /*          color={
                    errorsClients[index]?.expiration_date?.required
                      ? "danger"
                      : ""
                  } */
                  onChangeDate={(e) => {
                    setclientData({
                      ...clientData,
                      expiration_date: e,
                    });
                    /*                     setErrorsClientsfiled("expiration_date", e, {
                      required: true,
                    }); */
                  }}
                ></DatePicker>
              </div>
              <div className="col-3 mt-2">
                <TextField
                  hasLabel={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  value={clientData?.national_id}
                  label={translate.nationalId}
                  name="national_id"
                  min={11}
                  placeholder={translate.nationalIdPlaceholder}
                  errors={errorsClients[index]?.national_id}
                  color={
                    errorsClients[index]?.national_id?.number ? "danger" : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      national_id: e.target.value,
                    });
                    setErrorsClientsfiled("national_id", e.target.value, {
                      number: true,
                    });
                  }}
                ></TextField>
              </div>
              <div className="col-3  mt-2">
                <SelectField
                  onFocus={true}
                  hasLabel={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  label={translate.Nationality}
                  placeholder={translate.Nationality}
                  value={clientData?.nationality}
                  options={countries}
                  name="nationality"
                  onChange={(e) => {
                    handleChange({ name: "nationality", value: e });
                  }}
                  /*    errors={errorsClients[index]?.nationality} */
                  /*        color={
                    errorsClients[index]?.nationality?.required ? "danger" : ""
                  } */
                />
              </div>
              {/*    <div className="col-3 mt-2">
                <TextField
                  hasLabel={true}
                  value={clientData?.phone}
                  label={translate.phoneNumber}
                  name="phone"
                  min={11}
                  placeholder={userDetails.phoneNumberPlaceholder}
                  errors={errorsClients[index]?.phone}
                  color={
                    errorsClients[index]?.phone?.required
                      ? "danger"
                      : "" || errorsClients[index]?.phone?.min
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      phone: e.target.value,
                    });
                    setErrorsClientsfiled("phone", e.target.value, {
                      required: true,
                      min: 11,
                    });
                  }}
                ></TextField>
              </div> */}
              <div className="col-3  mt-2">
                <TextField
                  hasLabel={true}
                  disabled={true}
                  disabled={clientData.payment_status === "مدفوع"}
                  label={translate.country}
                  placeholder={translate.country}
                  value={"Egypt"}
                ></TextField>
              </div>
              {/*   <div className="col-3  mt-2">
                <SelectField
                  onFocus={true}
                  hasLabel={true}
                  value={clientData?.city_id}
                  label={translate.city}
                  placeholder={translate.city}
                  options={cities}
                  name="city_id"
                  errors={errorsClients[index]?.city_id}
                  color={
                    errorsClients[index]?.city_id?.required ? "danger" : ""
                  }
                  onChange={(e) => {
                    handleChange({ name: "city_id", value: e });
                    setErrorsClientsfiled("city_id", e, {
                      required: true,
                    });
                  }}
                />
              </div> */}
              {/* 
              <div className="col-3  mt-2">
                <SelectField
                  onFocus={true}
                  hasLabel={true}
                  value={clientData?.area_id}
                  label={translate.area}
                  placeholder={translate.area}
                  options={area}
                  errors={errorsClients[index]?.area_id}
                  color={
                    errorsClients[index]?.area_id?.required ? "danger" : ""
                  }
                  onChange={(e) => {
                    handleChange({ name: "area_id", value: e });
                    setErrorsClientsfiled("area_id", e, {
                      required: true,
                    });
                  }}
                />
              </div>
 */}
              {/*               <div className="col-9 mt-2">
                <TextField
                  hasLabel={true}
                  label={translate.address}
                  value={clientData?.address}
                  name="address"
                  errors={errorsClients[index]?.address}
                  color={
                    errorsClients[index]?.address?.required ? "danger" : ""
                  }
                  min={3}
                  placeholder={translate.address}
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      address: e.target.value,
                    });
                    setErrorsClientsfiled("address", e.target.value, {
                      required: true,
                    });
                  }}
                ></TextField>
              </div>
 */}{" "}
            </div>
            {/* Actions */}

            <div className="d-flex justify-content-end align-items-end mt-3">
              {listClients.length != 1 && (
              
                <button
                  className=" h4 text-danger text-bold m-0"
                  onClick={() => {
                    if (trip_id || switchTo.tripId) {
                      removeClient(data.id);
                    } else {
                      listClients.splice(index, 1);
                      dispatch({
                        type: "deleteClient",
                        index: listClients,
                      });
                    }
                  }}
                >
                  <i class="fas fa-stream  text-danger  "></i>
                  <i className="fas fa-trash text-danger mx-1 "></i>
                  {translate.remove}
                </button>
                
              )}

                {trip_id && (
                  <button
                    className=" text-success h4 m-0 text-bold mx-3"
                    onClick={() => {
                      submitEdit();
                      setEditing(false);
                    }}
                  >
                    <i class="fas fa-save mx-1"></i>
                    {translate.SaveChanges}
                  </button>
                )}
         
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  ) : (
    "    RunRun"
  );
}
