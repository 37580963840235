import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import { useAuthDispatch, useAuthState } from "context/auth";
import { useCompanyState } from "context/global";
import libphonenumber from "google-libphonenumber";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { fetchCities, fetchCitiesCountryId } from "services/lookups";
import Locale from "translations";

export default function CompanyDetails(props) {
  // ----------------------------------------------------------------------------------------------------
  // Component States / Variables / Constants
  // ----------------------------------------------------------------------------------------------------
  const { currentLanguage } = useCompanyState();
  const userRegister = useAuthState();

  const { companyDetails } = Locale;
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const companyRegister = useAuthState();
  let [cities, setCities] = useState([]);
  const [companyDetailsState, setCompanyDetailsState] = useState({
    name: companyRegister.companyDetails?.name ?? "",
    nameExistence: true,
    companyLanguage:
      companyRegister.companyDetails?.language ?? currentLanguage,
    address: companyRegister.companyDetails?.address ?? "",
    email: companyRegister.companyDetails?.email ?? "",
    country: companyRegister.companyDetails?.country ?? "",
    city: companyRegister.companyDetails?.city ?? "",
    phone: companyRegister.companyDetails?.phone ?? "",
    secondPhone: companyRegister.companyDetails?.secondPhone ?? "",
    countryPhoneCode: companyRegister.companyDetails?.phoneCode ?? "",
    countryCode: null,
    spin: false
  });

  const fetchCity = async (id) => {
    const cities = await fetchCitiesCountryId(id);
        if (cities&&cities.length > 0) {
          const formatted = cities.map((country) => ({
            value: country.id,
            label: country.names[currentLanguage]
          }));
          setCities(formatted);
        }else{
          setCities([]);
        }
  };

  Locale.setLanguage(currentLanguage);
  const dispatch = useAuthDispatch();
  const [errors, setErrors] = useState(
    companyRegister.companyDetails?.errors ?? {}
  );
  const validatePhone = (phone) => {
    try {
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(phone.toString(), companyDetailsState.countryPhoneCode),
        companyDetailsState.countryPhoneCode
      );
    } catch (err) {
      return false;
    }
  };

  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: "name", value: companyDetailsState.name },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "address", value: companyDetailsState.address },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "email", value: companyDetailsState.email },
        { required: true, email: true }
      ),
      ...validate(
        { name: "country", value: companyDetailsState.country },
        { required: true }
      ),
      ...validate(
        { name: "city", value: companyDetailsState.city },
        { required: true }
      ),
      ...validate(
        { name: "phone", value: companyDetailsState.phone },
        { required: true }
      )
    });
  };

  // SUbmit Form
  const submit = () => {
    dispatch({
      type: "Company_Details",
      payload: {
        name: companyDetailsState.name,
        language: companyDetailsState.companyLanguage,
        address: companyDetailsState.address,
        email: companyDetailsState.email,
        country: companyDetailsState.country,
        city: companyDetailsState.city,
        phone: companyDetailsState.phone,
        secondPhone: companyDetailsState.secondPhone,
        phoneCode: companyDetailsState.countryPhoneCode,
        errors: errors
      }
    });

    dispatch({ type: "Progress", progress: 100, step: 3 });
  };

  // Back Button
  const back = () => {
    dispatch({
      type: "Company_Details",
      payload: {
        name: companyDetailsState.name,
        language: companyDetailsState.companyLanguage,
        address: companyDetailsState.address,
        email: companyDetailsState.email,
        country: companyDetailsState.country,
        city: companyDetailsState.city,
        phone: companyDetailsState.phone,
        secondPhone: companyDetailsState.secondPhone,
        phoneCode: companyDetailsState.countryPhoneCode,
        errors: errors
      }
    });
    dispatch({ type: "Progress", progress: 0, step: 1, lastStep: 2 });
  };

  useEffect(async () => {
    if (isFormValid(errors) && userRegister.lastStep === 2) {
      //   const response = await nameAvailability(data);
      //   if (response.status === 200) {
      submit();
      //   }
    } else {
      dispatch({ type: "Progress", progress: 50, step: 2, lastStep: 2 });
    }
    setCompanyDetailsState({
      ...companyDetailsState,
      spin: false
    });
  }, [isErrorLoaded]);

  // Fetch Cities
  // useEffect(() => {
  //   async function fetchLookups() {
  //     if (companyDetailsState.country?.value) {
  //       const res = await fetchCities(companyDetailsState.country?.value);
  //       debugger
  //       if (res&&res.length > 0) {
  //         const formatted = res.map((country) => ({
  //           value: country.id,
  //           label: country.names[currentLanguage]
  //         }));
  //         setCities(formatted);
  //       }else{
  //         setCities([]);
  //       }
  //     }
  //   }
  //   fetchLookups();
  // }, [companyDetailsState.country]);

  const checkNameAvailability = () => {
    setCompanyDetailsState({
      ...companyDetailsState,
      spin: true
    });
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  // ----------------------------------------------------------------------------------------------------
  // Main Component Body UI
  // ----------------------------------------------------------------------------------------------------
  return (
    <>
      <h1 className="text-center text-title text-dark-blue">
        {Locale.mainTitle}
      </h1>

      <div className="bg-white p-2 px-3 radius-10 mt-3">
        <div className="row m-0">
          <div className="col-12">
            <p className="text-center text-caption text-gray-300 my-3">
              {companyDetails.topHint}
            </p>
          </div>

          {/* Company Name */}
          <div className="col-12">
            <TextField
              hasLabel={true}
              label={companyDetails.companyName}
              placeholder={companyDetails.companyNamePlaceholder}
              value={companyDetailsState.name}
              name="name"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    min: 3
                  })
                });
                setCompanyDetailsState({
                  ...companyDetailsState,
                  name: e.target.value,
                  nameExistence: true
                });
              }}
              min={3}
              color={errors.name?.required || errors.name?.min ? "danger" : ""}
              errors={errors.name}
            />
          </div>

          {/* Address */}
          <div className="col-12">
            <TextField
              hasLabel={true}
              label={companyDetails.address}
              placeholder={companyDetails.addressPlaceholder}
              value={companyDetailsState.address}
              name="address"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    min: 3
                  })
                });
                setCompanyDetailsState({
                  ...companyDetailsState,
                  address: e.target.value
                });
              }}
              min={3}
              color={
                errors.address?.required || errors.address?.min ? "danger" : ""
              }
              errors={errors.address}
            />
          </div>

          {/* Business Email */}
          <div className="col-12">
            <TextField
              hasLabel={true}
              label={companyDetails.businessEmail}
              placeholder={companyDetails.businessEmailPlaceholder}
              value={companyDetailsState.email}
              name="email"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    email: true
                  })
                });
                setCompanyDetailsState({
                  ...companyDetailsState,
                  email: e.target.value
                });
              }}
              color={
                errors.email?.required || errors.email?.email ? "danger" : ""
              }
              errors={errors.email}
            />
          </div>

          {/* Country */}
          <div className="col-md-6 pr-md-2">
            <SelectField
              label={companyDetails.country}
              value={companyDetailsState.country}
              options={props.countries}
              placeholder={companyDetails.countryPlaceholder}
              name="country"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country", value: e.value },
                    { required: true }
                  )
                });
                setCompanyDetailsState({
                  ...companyDetailsState,
                  country: e,
                  city: null,
                  phone: "",
                  secondPhone: ""
                });
                debugger
                fetchCity(e.value);
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country", value: companyDetailsState.country },
                    { required: true }
                  )
                })
              }
              color={errors.country?.required ? "danger" : ""}
              errors={errors.country}
            />
          </div>

          {/* City */}
          <div className="col-md-6 pl-md-2">
            <SelectField
              label={companyDetails.city}
              value={companyDetailsState.city}
              name="city"
              options={cities}
              disabled={cities?.length === 0}
              placeholder={companyDetails.cityPlaceholder}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "city", value: e.value },
                    { required: true }
                  )
                });
                setCompanyDetailsState({
                  ...companyDetailsState,
                  city: e
                });
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "city", value: companyDetailsState.city },
                    { required: true }
                  )
                })
              }
              color={errors.city?.required ? "danger" : ""}
              errors={errors.city}
            />
          </div>

          {/* Phone Number */}
          <div className="col-md-6 pr-md-2 mt-4">
            <TextField
              hasLabel={true}
              label={companyDetails.companyPhoneNumber}
              value={companyDetailsState.phone}
              name="phone"
              extraText={companyDetailsState.countryPhoneCode ?? "---"}
              extraTextPosition={"prepend"}
              placeholder={companyDetails.companyPhoneNumberPlaceholder}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "phone", value: e.target.value },
                    {
                      required: true,
                    }
                  )
                });
                setCompanyDetailsState({
                  ...companyDetailsState,
                  countryCode: companyDetailsState.countryPhoneCode,
                  countryPhoneCode: companyDetailsState.countryPhoneCode,
                  phone: e.target.value
                });
              }}
              color={
                errors.phone?.required || errors.phone?.phone ? "danger" : ""
              }
              errors={errors.phone}
            />
          </div>

          {/* Second Phone Number */}
          <div className="col-md-6 pl-md-2 mt-4">
            <TextField
              hasLabel={true}
              label={companyDetails.companyPhoneNumber}
              value={companyDetailsState.secondPhone}
              extraText={companyDetailsState.countryPhoneCode ?? "---"}
              extraTextPosition={"prepend"}
              placeholder={companyDetails.companyPhoneNumberPlaceholder}
              onChange={(e) => {
                setCompanyDetailsState({
                  ...companyDetailsState,
                  secondPhone: e.target.value
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3 m-0">
        <div className="col-md-2 offset-md-6">
          <button
            className="btn btn-secondary w-100 text-white opacity-30"
            onClick={back}
          >
            {companyDetails.back}
          </button>
        </div>
        <div className="col-md-4 p-0">
          <button
            className="btn btn-primary w-100"
            onClick={checkNameAvailability}
            // TODO fix then uncomment
            disabled={companyDetailsState.spin}
          >
            {companyDetailsState.spin ? (
              <Spinner color="light" size="sm" />
            ) : null}{" "}
            {companyDetails.next}
          </button>
        </div>
      </div>
    </>
  );
}
