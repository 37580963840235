import React, { useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router";
import { createWalletTransaction } from "services/wallet";
import StartTransfer from "./StartTransfer";
import TransferInfo from "./TransferInfo";
import useWallet from "modules/Yarvel/wallet/useWallet";
import "./walletTransfer.scss";

export default function Transfer() {
  const [amount, setAmount] = useState();
  const [info, setinfo] = useState(undefined);
  let history = useHistory();
  const { getBalance } = useWallet();
  const sendAmount = async () => {
    const res = await createWalletTransaction({ amount: amount });

    if (res.status === 201) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });

      setinfo(res.data.data);
      getBalance();
    }
  };

  return (
    <div className="wallet py-5">
      <div className="balance">
        {!info && (
          <StartTransfer setAmount={setAmount} sendAmount={sendAmount} />
        )}
        {info && <TransferInfo info={info} />}
      </div>
    </div>
  );
}
