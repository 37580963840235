import React, { useState } from "react";
import DatePicker from "../../../components/shared/Admin/DatePicker";
import TextField from "../../../components/shared/Admin/textField";
import Locale from "../../../translations";
import moment from "moment";

export default function Filter(props) {
  const { translate, translate_companies } = Locale;

  const currentDate = moment(new Date());
  const limit = moment(currentDate).subtract(1, "days");

  const [searchCompanyNameState, setSearchCompanyNameState] = useState('');
  const [searchState, setSearchState] = useState('');
  const [dateState, setDateState] = useState(null);
  const [dateQState, setDateQState] = useState('');

  const searchChangedHandler = (event) => {
    setSearchState(event.target.value);
  }

  const searchCompanyNameChangedHandler = (event) => {
    setSearchCompanyNameState(event.target.value);
  }

  const dateChangedHandler = (event) => {
    setDateQState(moment(event._d).format("YYYY-MM-DD"));
    setDateState(event);
  }

  const searchSubmitHandler = () => {
    props.FilterSubmitted(dateQState, searchState, searchCompanyNameState);
  }

  const resetSearchHandler = () => {
    setDateState('');
    setSearchState('');
    setSearchCompanyNameState('');
    props.ResetSubmitted();
  }

  return (
    <div className="CompanyFilterContainer">
      <div className="row no-gutters " style={{ width: "80% " }}>
      <div className="col mr-2 ">
          <TextField
            hasLabel={false}
            search={true}
            value={searchCompanyNameState}
            onChange={(event) => searchCompanyNameChangedHandler(event)}
            label={translate_companies.company_name}
            type={"text"}
            placeholder={translate_companies.company_name}
          >
            <i className="fas fa-search"></i>
          </TextField>
        </div>
        <div className="col mr-2 ">
          <TextField
            hasLabel={false}
            search={true}
            value={searchState}
            onChange={(event) => searchChangedHandler(event)}
            label={translate_companies.customer_name}
            type={"text"}
            placeholder={translate_companies.customer_name}
          >
            <i className="fas fa-search"></i>
          </TextField>
        </div>

        <div className="col mr-2  ">
          <DatePicker
            hasLabel={false}
            date={dateState}
            label={translate.examinationDate}
            placeholder={translate.examinationDatePlaceholder}
            displayFormat={"YYYY-MM-DD"}
            minDate={limit}
            onChangeDate={(e) => dateChangedHandler(e)}
          />
        </div>

        <div className="col  pt-1" >
          <button onClick={searchSubmitHandler} className="btn btn-primary px-5 py-2 font-weight-bold CompaniesList-button">
            {translate.search}
          </button>
          <button onClick={resetSearchHandler} className="btn text-muted font-weight-bold ">
            {" "}
            <i className="fas fa-retweet"></i>{translate.reset}
          </button>
        </div>

      </div>
    </div>
  );
}
