import React,{useState} from "react";
import Locale from "../../../../translations";
import { useHistory } from "react-router-dom";
import Filter from "./filter";
import { Link, NavLink, useParams } from "react-router-dom";
import Users from "./users"

function CompanyUsers() {
  const { translate, translate_companies } = Locale;
  let history = useHistory();

  const { company_id } = useParams();

  return (
    <section>
      <div className="container ">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate_companies.users}</h3>
          <NavLink
            exact={true}
            to={`/admin/companies/users/add/`+company_id}
            activeClassName="is-active"
          >
            <button
              type="button"
              className="btn btn-success px-4 py-2 font-weight-bold "
              onClick={() => {
                history.push("/admin/companies/users/add/"+ company_id);
              }}
            >
              <i className="fas fa-plus-circle mr-2"></i>
              {translate.addUser}
            </button>
          </NavLink>
        </div>
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2  ">
          <Filter />
        </header>
        <Users companyID={company_id} />
      </div>
    </section>
  );
}

export default CompanyUsers;
