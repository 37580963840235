import React from "react";
import Locale from "../../../translations";
import {  NavLink } from "react-router-dom";
import Orders from "./orders";

function MedicalRequests() {
  const { translate_companies } = Locale;

  return (
    <section>
      <div className="AdminContainer-custom container ">
        <header className="d-flex CompaniesList-orders py-3 px-2  ">
        <div>
          <h3 className="text-muted font-weight-bold CompaniesList-title">{translate_companies.orders_header_title}</h3>
        </div>
          <div>
            <NavLink
              exact={true}
              to={`/admin/orders-inprogress`}
              activeClassName="is-active"
              className="text-bold mx-1 px-3 bg-brwon rounded text-muted CompaniesList-LinkNotActive"
            >
              {translate_companies.orders_working}
            </NavLink>
            <NavLink
              exact={true}
              to={`/admin/medical-services-requests`}
              activeClassName="is-active"
              className="text-bold mx-1 px-3 bg-brwon rounded text-muted CompaniesList-LinkActive"
            >
              {translate_companies.orders_checked}
            </NavLink>
          </div>
        </header>
        <Orders />
      </div>
    </section>
  );
}

export default MedicalRequests;
