import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../../translations";

export default function User(props) {
	const { user, company_id } = props;
	//const { Labs } = Locale;
	const { translate } = Locale;


	console.log(props);
	return (
    <>
      <tr>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.phone_number}</td>
        <td
          className={user.status === "active" ? "text-green" : "text-warning"}
        >
          {" "}
          {user.status}
        </td>
        <td>
          <div className="actions">
            <span className="text-primary">
              <NavLink
                exact={true}
                to={`/admin/companies/users/edit/`+company_id+`/`+user.id}
                activeClassName="is-active"
                className="p-2 text-primary mr-3 btn-link "
              >
                <i className="fas fa-edit"></i>
                {translate.edit}
              </NavLink>

              {user.status === "active" ? (
                <button
                  type="button"
                  onClick={() => props.changeStatusUser(user.id, "deactivate")}
                  className="btn btn-link text-danger stopping"
                >
                  <div className="square-stop-container">
                    <span className="square-stop"></span>
                  </div>
                  {/* <i className="fas fa-stop"></i> */}
                  {translate.deactive}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => props.changeStatusUser(user.id, "activate")}
                  className="btn btn-link text-success"
                >
                  <i class="far fa-play-circle"></i>
                  {translate.active}
                </button>
              )}
            </span>

            {/* <button onClick={() => props.togglePassword(user.id)}>
              {" "}
              <span className="mx-3 text-primary btn-link">
                <i className="fas fa-lock"></i> {translate.changePassword}
              </span>{" "}
            </button> */}

            <button className="btn btn-danger mx-3">{translate.DeleteUser}</button>
          </div>
        </td>
      </tr>
      {/* 
			<tr>
				<td>{user.name}</td>
				<td>{user.email}</td>
				<td> {user.group}</td>
				<td>
					{user.status == "active" ? (
						<i className="far fa-check-circle btn fa-2x text-success"></i>
					) : (
						<i class="far fa-times-circle btn fa-2x text-danger"></i>
					)}
				</td>

				<td className="w-10">
					
						<button
							type="button"
							onClick={() => props.toggleEdit(user.id)}
							className="btn btn-link text-gray"
						>
							<i className="far fa-edit"></i> {Labs.edit}
						</button>
				
				</td>
				<td className="w-10">
					{user.status == "active" ? (
					
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "deactivate")}
								className="btn btn-link text-danger"
							>
								<i className="fas fa-stop"></i>
								{Labs.deactive}
							</button>
					) : (
						
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "activate")}
								className="btn btn-link text-success"
							>
								<i class="far fa-play-circle"></i>
								{Labs.active}
							</button>
						
					)}
				</td>
				<td className="w-10">
					
						<button
							type="button"
							onClick={() => props.togglePassword(user.id)}
							className="text-gray btn btn-link "
						>
							<i className="fas fa-unlock-alt"></i>
							{Labs.changePassword}
						</button>
					
				</td>
				<td className="w-10">
				 <button type="button" className="btn btn-danger">
						حذف
					</button> 
				</td>
			</tr>
			*/}
    </>
  );
}
