const reducersAddClient = (state, action) => {
  switch (action.type) {
    case "addService": {
      return { ...state, service: action.data };
    }
    case "newAddService": {
      return {
        ...state,
        service: action.data,
        listClients: [],
        tripInfoState: {},
        listReservation: [],
        tripInfo: {},
      };
    }

    case "addTrip": {
      return { ...state, tripInfoState: action.data };
    }

    case "addClient": {
      return { ...state, listClients: [...state.listClients, {}] };
    }
    case "addReservation": {
      state.listReservation[action.index] = { ...action.data };
      return { ...state };
    }
      
    case "removeReservation": {
      state.listReservation =[];
      return { ...state };
    }
    case "deleteClient": {
      if (state.listClients.length != 0) {
        return {
          ...state,
          listClients: [...action.index],
        };
      }
    }
    case "addClientData": {
      state.listClients[action.index] = { ...action.payload };
      return { ...state };
    }

    case "clientsNumber": {
      let arr = [...Array(+action.count)].map((_, index) => {
        return {};
      });
      return { ...state, listClients: [...arr] };
    }

    case "clientsEdit": {
      let arr = action.data.map((item) => {
        return { ...item };
      });

      return { ...state, listClients: [...arr] };
    }

    case "clients": {
      return { ...state, listClients: action.payload };
    }

    default: {
      return state;
    }
  }
};
export default reducersAddClient;
