import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_ADMIN_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

export const SafarnaURL = `https://${domain}/api`;

const URL = `http://${subdomain}.${domain}/api`;

console.log('path', SafarnaURL);

export const singin = async (data) => {
  return await axios
    .post(`${SafarnaURL}/auth/admin-login`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getClientList = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/pcr/reservation`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const addClientsWithpcr = async (data) => {
  return await axios
    .post(`${SafarnaURL}/trips`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const addClients = async (data) => {
  return await axios
    .post(`${SafarnaURL}/travelers/bulk`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateTrip = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/trips/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateClients = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/travelers/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const saveReservation = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/pcr/reservation/${id}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const createPayment = async (data) => {
  return await axios
    .post(`${SafarnaURL}/payments`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const getClientsReservation = async (id) => {
  return await axios
    .get(`${SafarnaURL}/trips/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const veiwReservation = async (id) => {
  return await axios
    .get(`${SafarnaURL}/pcr/reservation/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getMRZ = async (mrz) => {
  return await axios
    .post(`${SafarnaURL}/travelers/mrz`, mrz)
    .then((res) => res)
    .catch((err) => err.response);
};

export const deletClient = async (id) => {
  return await axios
    .delete(`${SafarnaURL}/travelers/${id}?company_id=1`)
    .then((res) => res)
    .catch((err) => err.response);
};

/* Team Managment  */
export const ListUser = async (status) => {
  return await axios
    .get(`${SafarnaURL}/admins`,{
			params: cleanEmpty(status),
		})
    .then((res) => res)
    .catch((err) => err.response);
};

export const RetrievedUser = async (id) => {
  return await axios
    .get(`${SafarnaURL}/admins/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const ListGroups = async (status) => {
  return await axios
    .get(`${SafarnaURL}/groups`,{
			params: cleanEmpty(status),
		})
    .then((res) => res)
    .catch((err) => err.response);
};

export const changeStatus = async (id, status, type) => {
  return await axios
    .put(`${SafarnaURL}/admins/${id}/${status}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const changePasswordAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/admins/auth/change-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editUserAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/admins/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const deleteUser = async (id) => {
  return await axios
    .delete(`${SafarnaURL}/admins/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const listGroupsDp = async (type) => {
  return await axios
    .get(`${SafarnaURL}/groups?type=${type}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const listPermissionsDB = async () => {
  return await axios
    .get(`${SafarnaURL}/groups/permissions`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addUserAPI = async (data) => {
  return await axios
    .post(`${SafarnaURL}/admins`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addGroupAPI = async (data) => {
  return await axios
    .post(`${SafarnaURL}/groups`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const RetrievedGroup = async (id) => {
  return await axios
    .get(`${SafarnaURL}/groups/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editGroupAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/groups/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
/*   */

/* Companies  */

export const ListCompany = async (status, IsAdmin) => {
  if (IsAdmin === 'true') {
    return await axios
      .get(`${SafarnaURL}/companies`, {
        params: cleanEmpty(status),
      })
      .then((res) => res)
      .catch((err) => err.response);
  } else {
    return await axios
      .get(`${SafarnaURL}/companies/managed-by-me`, {
        params: cleanEmpty(status),
        Authorization: localStorage.getItem("Admin_token")
      })
      .then((res) => res)
      .catch((err) => err.response);
  }
};

export const changeStatusCompany = async (id, status) => {
  return await axios
    .put(`${SafarnaURL}/companies/${status}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const acceptCompany = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/companies/accept/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const rejectCompanyAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/companies/reject/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const RetrievedCompany = async (id) => {
  return await axios
    .get(`${SafarnaURL}/companies/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editCompanyAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/companies`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const listCountriesDp = async () => {
  return await axios
    .get(`${SafarnaURL}/lookups/countries`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const listCitiesDp = async (id) => {
  return await axios
    .get(`${SafarnaURL}/lookups/cities?country_id=${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

/*   */

/* Company Users  */
export const company_ListUser = async (adminType, userID) => {
  return await axios
    .get(`${SafarnaURL}/admins?company_id=${userID}&admin-type=${adminType}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const company_RetrievedUser = async (id) => {
  return await axios
    .get(`${SafarnaURL}/admins/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const company_ListGroups = async () => {
  return await axios
    .get(`${SafarnaURL}/groups`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const company_changeStatus = async (id, status, type) => {
  return await axios
    .put(`${SafarnaURL}/admins/${id}/${status}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const company_changePasswordAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/admins/auth/change-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const company_editUserAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/admins/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const company_listGroupsDp = async () => {
  return await axios
    .get(`${SafarnaURL}/groups`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const company_addUserAPI = async (data) => {
  return await axios
    .post(`${SafarnaURL}/admins`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
/*   */

/* Company Orders  */
export const company_ListOrder = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/pcr/reservation`,{
			params: cleanEmpty(filter),
		})
    .then((res) => res)
    .catch((err) => err.response);
};

/*   */


/* Company Wallet  */

export const company_getCurrentBalance = async (id) => {
  return await axios
    .get(`${SafarnaURL}/wallet/show?company_id=${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const company_getWalletRequest = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/wallet/request`,{
			params: cleanEmpty(filter),
		})
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const company_getWalletDeals = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/wallet/transaction-logs`,{
			params: cleanEmpty(filter),
		})
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const addBalanceToWallet = async (data) => {
  return await axios
    .put(`${SafarnaURL}/wallet/update`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addBalanceToSafa = async (data) => {
  return await axios
    .post(`${SafarnaURL}/wallet/safa/limit`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const payBalanceToSafa = async (data) => {
  return await axios
    .post(`${SafarnaURL}/wallet/safa/add`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const wallet_changeStatusRequest = async (id, status) => {
  return await axios
    .put(`${SafarnaURL}/wallet/request/${id}/${status}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getWalletDealsWithID = async (id) => {
  return await axios
    .get(`${SafarnaURL}/wallet/transaction?id=${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};


export const getWalletRequestWithID = async (id) => {
  return await axios
    .get(`${SafarnaURL}/wallet/request/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

/*   */


/* statistics  */

export const getAdminStatistics = async () => {
  return await axios
    .get(`${SafarnaURL}/statistics/admin`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

/*  */

export const company_veiwReservation = async (id) => {
  return await axios
    .get(`${SafarnaURL}/trips/${id}?show=1`)
    .then((res) => res)
    .catch((err) => err.response);
};

/* Medical Services Requests  */
export const medical_ListOrder = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/pcr/reservation`,{
			params: cleanEmpty(filter),
		})
    .then((res) => res)
    .catch((err) => err.response);
};

export const medical_veiwReservation = async (id) => {
  return await axios
    .get(`${SafarnaURL}/trips/${id}?show=1`)
    .then((res) => res)
    .catch((err) => err.response);
};

/*   */


/* Accounts  */
export const ListAccounts = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/payments/accounts/list`,{
			params: cleanEmpty(filter),
		})
    .then((res) => res)
    .catch((err) => err.response);
};

export const ListChecksDropDown = async () => {
  return await axios
    .get(`${SafarnaURL}/pcr/lookups/checks-dropdown`)
    .then((res) => res)
    .catch((err) => err.response);
};


export const ListAccountsExport = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/payments/accounts/list/export`,{
    params: cleanEmpty(filter),
    responseType: 'blob'
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `payments_${(new Date()).toLocaleString()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => err.response);
};
/*   */