export default function reducers(state, action) {
  switch (action.type) {
    case "setLoading":
      return { ...state, loading: action.payload };
    case "setLocale":
      localStorage.setItem("currentLanguage","ar");
      window.location.reload();
      return { ...state, currentLanguage: action.payload };
    case "setBalance":
      return { ...state, balance: action.balance };
    case "setBalanceCredit":
      return {
        ...state,
        safaWallet: action.balance,
        safaLimit: action.limit,
      };
    case "logout":
      localStorage.clear();
      window.location.href = "/login";
      return { ...state };
    case "login":
       localStorage.setItem("currentLanguage", "ar");
      localStorage.setItem("isAuth", true);
      localStorage.setItem("token", action.payload.access_token);
      localStorage.setItem("userType", action.payload.user_type);
      localStorage.setItem("company_id", action.payload.company_id);
      localStorage.setItem("company_name", action.payload.company_name);
      localStorage.setItem(
        "permissions",
        JSON.stringify(action.payload.permissions)
      );
      return {
        ...state,
        isAuth: true,
        token: action.payload.access_token,
        permissions: action.payload.permissions,
        userType: action.payload.user_type,
        company_id: action.payload.company_id,
      };
    // eslint-disable-next-line no-fallthrough
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
