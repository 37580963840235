import { createContext, useContext, useReducer } from "react";
import reducersAddClient from "./reducersAddClient";
const clients = createContext()
const clientsDispatch = createContext()


// to get data from context  
function useClientsState() {
  const context = useContext(clients);
  if (context === undefined) {
    throw new Error("context is undefined");
  }
  return context;
}

// to get dispatch function

function useClientsDispatch() {
  const context = useContext(clientsDispatch);
  if (context === undefined) {
    throw new Error("Dispatch is undefined");
  }
  return context;
}

const initialState = {
  listClients: [],
  tripInfo: {},
  listReservation: [],
  service:[]

};




function AddClientProvider({ children }) {
  const [state, dispatch] = useReducer(reducersAddClient, initialState);
  return (
    <clients.Provider value={state} >
      <clientsDispatch.Provider value={dispatch}>
        {children}
      </clientsDispatch.Provider>
    </clients.Provider>
  );
}


export { AddClientProvider, useClientsState, useClientsDispatch };