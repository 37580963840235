import React from "react";
import Checkbox from "components/shared/checkbox";
import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import Locale from "translations";

export default function LabBooking({
  errorsReservation,
  setErrorsReservation,
  switchTo,
  selectedGrade,
}) {
  const { translate } = Locale;
  return (
    <div className="row">
      <div className="col-3">
        <SelectField
          onFocus={true}
          hasLabel={true}
          label={translate.governorate}
          placeholder={"مصري "}
        />
      </div>
      <div className="col-3">
        <SelectField
          onFocus={true}
          hasLabel={true}
          label={translate.city}
          placeholder={"مصري "}
        />
      </div>
      <div className="col-3">
        <SelectField
          onFocus={true}
          hasLabel={true}
          label={translate.labName}
          placeholder={"مصري "}
        />
      </div>
      <div className="col-3">
        <DatePicker
          hasLabel={true}
          label={translate.examinationDate}
          placeholder={"10-10-201 | 09:45PM"}
        ></DatePicker>
      </div>

      <div className="col-12 mt-5">
        <Checkbox
          hasLabel={true}
          className="radio_bnt"
          label={translate.UseDateForAll}
        />
      </div>
    </div>
  );
}
