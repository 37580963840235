import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";

import Locale from "../../../../translations";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";


//import { store } from "react-notifications-component";
import { store } from "react-notifications-component";
import {
  company_changePasswordAPI,
  company_changeStatus,
  company_ListUser,
} from "../../../../services/admin";
import User from "./User";
import validate, { isFormValid } from "../../../../helpers/validate";

export default function Users(props) {
  const { translate, TeamManagement } = Locale;
  const { companyID } = props;
  /** */
  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        {
          name: "password",
          value: changePassword.password,
        },
        {
          required: true,
          password: true,
          min: 8,
        }
      ),
    });
  };

  /***********Status User********************** */
  const [changePassword, setChangePassword] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [modalPassword, setModalPassword] = useState(false);
  const [userid, setUserId] = useState(0);
  const [errors, setErrors] = useState({});

  const togglePassword = (e) => {
    setChangePassword({});
    setUserId(e);
    setModalPassword(!modalPassword);
  };

  const changeStatusUser = async (id, status) => {
    const res = await company_changeStatus(id, status);
    if (res.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      const usersData = await company_ListUser('company-admin', companyID);
      if (usersData.status === 200) {
        setUsers(usersData.data);
      }
    }
  };

  /********Fetch List Users**** */
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const User = await company_ListUser('company-admin', companyID);
      setUsers(User?.data);
    }
    fetchData();
  }, []);

  /**************************************** */

  // console.log(user.data.data);
  const AllUsers =
    users && users?.data?.length > 0
      ? users?.data?.map((user, index) => (
          <User
            company_id={companyID}
            user={user}
            index={index}
            changeStatusUser={changeStatusUser}
            togglePassword={togglePassword}
            //toggleEdit={toggleEdit}
            // removeItem={removeItem}
          />
        ))
      : "";
  /** */

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePasswordUser = async (payload) => {
      const res = await company_changePasswordAPI(userid, payload);
      if (res.status === 200) {
        togglePassword(0);
        store.addNotification({
          title: "info!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
  };

  const validationSchema = yup.object({
    password: yup
      .string(translate.passwordPlaceholder)
      .min(8, translate.passwordErrorMin)
      .required(translate.passwordRequired),
    confirmPassword: yup
      .string(translate.password_confirmationPlaceholder)
      .min(8, translate.passwordErrorMin)
      .test({
        name: "passwords-match",
        exclusive: false,
        params: {},
        message: translate.ShouldMatchPassword,
        test: function (value) {
          return value === this.parent.password;
        },
      })
      .required(translate.password_confirmationPlaceholder),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        admin_id: userid,
        password: values.password,
        password_confirmation: values.confirmPassword,
      };
      console.log("payload", userid);

      changePasswordUser(payload);
    },
  });

  return (
    <div className=" mt-3 p-0">
      <Table>
        <thead>
          <tr className="text-grey">
            <th> {translate.userName}</th>
            <th>{translate.userEmail}</th>
            <th>{translate.mobileNumber}</th>
            <th>{translate.status}</th>
            <th>{translate.tools}</th>
          </tr>
        </thead>
        <tbody className="table-stripe ">{AllUsers}</tbody>
      </Table>

      {/*************************** Model Password */}

      <div>
        <Modal isOpen={modalPassword} toggle={togglePassword}>
          <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
            <p className="h3 text-secondary ">{translate.changePassword}</p>
            <i
              className="far fa-times-circle fa-lg text-secondary "
              onClick={togglePassword}
            ></i>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormControl
                    className={`WorkTeamInput ${
                      formik.touched.password && formik.errors.password
                        ? "WorkTeamInputError"
                        : ""
                    }`}
                    variant="filled"
                  >
                    <InputLabel
                      shrink={true}
                      htmlFor="filled-adornment-password"
                    >
                      {TeamManagement.passwordPlaceholder}
                    </InputLabel>
                    <FilledInput
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      placeholder={TeamManagement.passwordPlaceholder}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.password &&
                    Boolean(formik.errors.password) ? (
                      <FormHelperText
                        className={"PasswordInputError"}
                        id="component-error-text"
                      >
                        {formik.errors.password}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
                <div className="col-md-12">
                  <FormControl
                    className={`WorkTeamInput ${
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                        ? "WorkTeamInputError"
                        : ""
                    }`}
                    variant="filled"
                  >
                    <InputLabel
                      shrink={true}
                      htmlFor="filled-adornment-password"
                    >
                      {translate.password_confirmation}
                    </InputLabel>
                    <FilledInput
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      placeholder={TeamManagement.repeatPasswordPlaceholder}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword) ? (
                      <FormHelperText
                        className={"PasswordInputError"}
                        id="component-error-text"
                      >
                        {formik.errors.confirmPassword}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">
                {translate.submit}
              </Button>{" "}
              <Button color="secondary" onClick={togglePassword}>
                {translate.cancel}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>

      {/*********************Model password  */}
    </div>
  );
}
