import "@fortawesome/fontawesome-free/css/all.min.css";
import Loader from "components/Loader";
import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import AxiosConfig from "services/axios-config";
import Layout from "./components/layout";
import { useCompanyState } from "./context/global";
import Routes from "./routes";
import "./scss/index.scss";
import Locale from "./translations";
import { createBrowserHistory } from "history";

function App() {
  const { currentLanguage, loading } = useCompanyState();

  const history = createBrowserHistory();
  console.log(history.location.pathname);

  AxiosConfig();
  Locale.setLanguage(currentLanguage);
  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", currentLanguage === "ar" ? "rtl" : "ltr");

  return (
    <div
      style={{
        textAlign: currentLanguage === "ar" ? "right" : "left",
        fontFamily: currentLanguage === "ar" ? "Cairo" : "Roboto",
      }}
    >
      {loading && <Loader />}

      <ReactNotification />
      <Router>
        <Layout>{Routes}</Layout>
      </Router>
    </div>
  );
}

export default App;
