import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { NavLink } from "react-router-dom";
import { store } from "react-notifications-component";

import { useAdminState } from "../../../context/Admin/global";
import { listPermissionsDB, addGroupAPI } from "../../../services/admin";
import Locale from "../../../translations";

const AddGroup = (props) => {
  const { translate, TeamManagement } = Locale;
  const [permissionsState, setPermissionsState] = useState([]);
  const [haedOfpermissionsState, setHaedOfpermissionsState] = useState([]);
  const { currentLanguage } = useAdminState();

  const [permissionChecked, setPermissionChecked] = useState({});
  const [errorSubmit, setErrorSubmit] = useState(false);

  const handleChange = (event) => {
    let checkedNow = {
      [event.target.name] : event.target.checked
    };

    setPermissionChecked({ ...permissionChecked, [event.target.id] : checkedNow });
  };

  const handleChangeAll = (event,data,index) => {
    console.log('haedOfpermissionsState',haedOfpermissionsState);
    let newHaedOfpermissions = haedOfpermissionsState.slice();
    newHaedOfpermissions[index] = true;
    console.log('new HaedOfpermissions',newHaedOfpermissions);
    setHaedOfpermissionsState(newHaedOfpermissions);

    let childArr = [];
    let newArr = Object.values(Object.assign({},permissionChecked));

    data.map(item => {
      childArr.push(item.id);      
    });
    
    for (let j = 0; j < newArr.length; j++) {
      childArr.map((item) => {
        if(Object.keys(newArr[j]).toString() === item.toString()){
          newArr[j] = {[Object.keys(newArr[j]).toString()] : true}
        }
      });
    }

    setPermissionChecked(newArr);
  };


  useEffect(() => {
    async function fetchData() {
      const PermissionsDB = await listPermissionsDB();
      if (PermissionsDB.status === 200) {
        let headPermissionArr = new Array(Object.keys(PermissionsDB.data).length);
        headPermissionArr.fill(false);
        setHaedOfpermissionsState(headPermissionArr);
        //console.log('head',Object.keys(PermissionsDB.data).length);
        let permissionArr = Object.values(PermissionsDB.data);
        setPermissionsState(permissionArr);
        let objectChecked = {};
        let Arr = permissionArr.map((permission) => {
          return permission.map(item => {
            return { ...objectChecked, [item.id]: false };
          }
            );
        }
       );

       convertArray(Arr);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log('permissionChecked - Now', permissionChecked)
  }, [permissionChecked])

  const convertArray = (Arr)=> {
    var newArr = [];
    
    for(var i = 0; i < Arr.length; i++)
    {
        newArr = newArr.concat(Arr[i]);
    }

    setPermissionChecked(newArr);
    return newArr;
  }

  const addNewGroup = async (payload) => {
    const res = await addGroupAPI(payload);

    if (res.status === 201) {
      console.log('notifications')
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      props.history.push('/Admin/team-management/groups')
    }
  };

  const validationSchema = yup.object({
    groupName: yup
      .string(translate.groupNameError)
      .required(translate.groupNameRequired),
  });

  const formik = useFormik({
    initialValues: {
      groupName: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let permissionSubmit = [];
      for (let key in permissionChecked) {
        let objNew = permissionChecked[key];
        for(let aKey in objNew) {
          if(objNew[aKey] === true){
            permissionSubmit.push(aKey);
          }
        }
      }

      let payload = {
        name: values.groupName,
        type: localStorage.getItem('Admin_userType'),
        permissions: permissionSubmit
      };

      console.log("payload", payload);

      if(permissionSubmit.length > 0){
        addNewGroup(payload);
      }else {
        setErrorSubmit(true);
        console.log('not have permissionSubmit');
      }
    },
  });

  let count = 0;

  return (
    <section>
      <div className="container my-4 ">
        <header className=" AdminTeamlist_header border py-3 px-3">
          <div className="d-flex justify-content-between  ">
            <h3 className="text-muted font-weight-bold AdminTeamlist_title">
              {translate.addGroup}
            </h3>
          </div>
        </header>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-50 my-3">
            <TextField
              className={`WorkTeamInput ${
                formik.touched.groupName && formik.errors.groupName
                  ? "WorkTeamInputError"
                  : ""
              }`}
              id="groupName"
              name="groupName"
              label={translate.groupName}
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.groupName}
              onChange={formik.handleChange}
              error={
                formik.touched.groupName && Boolean(formik.errors.groupName)
              }
              helperText={formik.touched.groupName && formik.errors.groupName}
              placeholder={translate.groupName}
            />
          </div>

          <header className=" AdminTeamlist_header border py-3 px-3">
            <div className="d-flex justify-content-between  ">
              <h3 className="text-muted font-weight-bold AdminTeamlist_title">
                {translate.manageGroupPermissions}
              </h3>
            </div>
          </header>

          <div className="all-boxs">
            {permissionsState
              ? permissionsState.map((permission,index) => {
                console.log('-permission', permission);
                  return (
                    <div key={permission[0].group_name} className="boxs">
                      <div className="box-header">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={haedOfpermissionsState[index]}
                              onChange={(event)=>{
                                handleChangeAll(event,permission,index);
                              }}
                              name={permission[0].group_name}
                            />
                          }
                          label={permission[0].group_name}
                        />
                      </div>
                      <div className="box-body">
                        <FormControl component="fieldset">
                          <FormGroup>
                          {permission.map(item => {
                            
                            let CheckboxRender = (<FormControlLabel
                              control={
                                <Checkbox
                                  checked={Object.values({...permissionChecked[count]}).toString() === 'false' ? false : true}
                                  onChange={handleChange}
                                  id={count}
                                  name={item.id}
                                />
                              }
                              label={currentLanguage === "en" ? item.display_name.en : item.display_name.ar}
                            />);
                            count ++;
                            return CheckboxRender;
                          })}
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          {errorSubmit 
          ? <p className="GroupError">{TeamManagement.errorPermissions}</p> 
          : null}

          <div className=" btns d-flex justify-content-between align align-items-end">
            <NavLink
              exact={true}
              to={`/Admin/team-management/groups`}
              activeClassName="is-active"
            >
              <button className="btn btn-secondary px-5 py-2 ">
                {translate.back}
              </button>
            </NavLink>
            <button className="btn btn-success px-5 py-2 " type="submit">
              {translate.add}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AddGroup;
