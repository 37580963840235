import React from "react";
import Header from "./Header";
export default function Layout(props) {
  const AdminIsAuth = localStorage.getItem("Admin_isAuth");

  return (
    <>
      {AdminIsAuth && <Header />}
      <section>{props.children}</section>
    </>
  );
}
