import React, { useState, useEffect } from "react";
import { store } from "react-notifications-component";

import { changeStatusCompany, ListCompany } from "../../../services/admin";
import Company from "./Company";
import Filter from "./filter";

import Locale from "../../../translations";
import Pagination from "components/shared/Pagination";

export default function Companies(props) {
  const { translate, translate_companies } = Locale;
  const [companies, setCompanies] = useState([]);
  const [filter, setFilter] = useState({
    status: 'active,deactivated',
    q: '',
    page: 1,
  });

  const IsAdmin = localStorage.getItem("is_Admin");

  useEffect(() => {
    async function fetchData() {
      const allCompanies = await ListCompany(filter,IsAdmin);
      setCompanies(allCompanies?.data);
    }
    fetchData();
  }, [filter]);

  const ChangeStatusCompany = async (id, status) => {
    const res = await changeStatusCompany(id, status);
    if (res.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      const companiesData = await ListCompany(filter,IsAdmin);
      if (companiesData.status === 200) {
        setCompanies(companiesData.data);
      }
    }
  };

  // pagination
  const goTo = async (page) => {
    setFilter({ ...filter, page: page });
  };

  const AllCompanies =
    companies && companies?.data?.length > 0
      ? companies?.data?.map((item, index) => (
        <Company
          company={item}
          index={index}
          changeStatusCompany={ChangeStatusCompany}
        />
      ))
      : "";

  const filterSubmitted = (statusQ, searchQ) => {
    if (statusQ !== '0') {
      setFilter({ ...filter, status: statusQ, q: searchQ, page: 1 });
    } else {
      setFilter({ ...filter, q: searchQ, page: 1 });
    }
  }

  const resetSubmitted = () => {
    setFilter({ ...filter, status: 'active,deactivated', q: '', page: 1 });
  }

  return (
    <React.Fragment>
      <Filter
        ResetSubmitted={() => resetSubmitted()}
        FilterSubmitted={(status, searchQ) => filterSubmitted(status, searchQ)} />

      <div className="">
        <table className="table">
          <thead className="bg_table ">
            <tr className="table_head">
              <th className="CompanyHead-title" scope="col">{translate_companies.company_name}</th>
              <th className="CompanyHead-title" scope="col">{translate_companies.email}</th>
              <th className="CompanyHead-title" scope="col">{translate_companies.manager_name}</th>
              <th className="CompanyHead-title" scope="col">{translate_companies.mobile}</th>
              <th className="CompanyHead-title" scope="rowgroup">{translate_companies.total_orders}</th>
              <th className="CompanyHead-title" scope="rowgroup">{translate_companies.company_balance}</th>
              <th className="CompanyHead-title" scope="rowgroup">{translate_companies.safa_credit}</th>
              <th className="CompanyHead-title" scope="rowgroup">{translate_companies.status}</th>
              <th className="CompanyHead-title" scope="col">{translate_companies.tools}</th>
            </tr>
          </thead>
          <tbody>
            {AllCompanies}
          </tbody>
        </table>

        <Pagination info={companies?.meta} goTo={goTo} items={10} />
      </div>
    </React.Fragment>
  );
}
