import TextField from "components/shared/textField";
import React, { useState } from "react";
import Locale from "translations";
import { useHistory } from "react-router";
import { useClientsDispatch } from "context/addClient";
export default function PCR({ examnation, services }) {
  const { translate } = Locale;
  let history = useHistory();
  const [clientsNumber, setClientsNumber] = useState();
  const dispatch = useClientsDispatch();

  return (
    <div className="text_filed p-4  medical_box my-3">
      <div className="row align-items-center justify-content-between">
        <figure className="rounded-circle bg-white p-5 border">
          {examnation.check_id != 1 && (
            <i class="fas fa-syringe fa-3x text-primary"></i>
          )}
          {examnation.check_id === 1 && (
            <i class="fas fa-virus fa-3x text-primary"></i>
          )}
        </figure>
        <div className="d-flex flex-column justify-content-start mx-5 col-5">
          <h3 className="text-primary text-bolder">{examnation.title}</h3>
          <div className="d-flex align-items-baseline">
            <i class="far fa-clock mx-1"></i>
            <p className="m-0 mx-1 text-muted">النتيجة خلال:</p>
            <p className="m-0 mx-1 text-bolder">{examnation.time} ساعه</p>
          </div>

          <div className="d-flex align-items-baseline">
            <i class="fas fa-tag mx-1"></i>
            <p className="m-0 mx-1  text-muted">السعر:</p>
            <p className="m-0 mx-1 text-success text-bolder">
              {examnation.price}
            </p>
          </div>

          <div className="d-flex align-items-baseline">
            <p className="m-0 mx-1 bg-pay text-pay p-2">
              <i class="fas fa-money-bill-wave m-0 mx-1 text-pay"></i>
              {translate.walletCommission}{" "}
              <span className="text-bolder">{examnation.debit_comission} </span>
            </p>
            <p className="m-0 mx-1 bg-pay text-pay p-2">
              <i class="fas fa-wallet m-0 mx-1 text-pay"></i>
              {translate.companyCommission}{" "}
              <span className="text-bolder">{examnation.credit_comission}</span>
            </p>
          </div>
        </div>
        <div className="d-flex  align-items-start col">
          <TextField
            className="p-3"
            hasLabel={false}
            placeholder={translate.NumberOfPeople}
            type={"text"}
            onChange={(e) => {
              setClientsNumber(e.target.value);
            }}
          ></TextField>

          <button
            type="button"
            class="btn btn-success p-3 mt-1 mx-4  text-bolder w-50  "
            onClick={() => {
              history.push(
                `/medical/services/${clientsNumber ? clientsNumber : 1}/${
                  examnation.check_id
                }`
              );
              dispatch({
                type: "newAddService",
                data: { ...examnation, ...services },
              });
            }}
          >
            {translate.ServiceReservation}
          </button>
        </div>
      </div>
    </div>
  );
}
