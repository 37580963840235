import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function CompanyRouter({ component: Component, ...props }) {
  const isAuth = localStorage.getItem("isAuth");
  if (isAuth) {
    return (
      <Route
        {...props}
        render={(matchProps) => <Component {...matchProps} />}
      />
    );
  } else {
    return <Redirect to="/login" />;
  }
}
