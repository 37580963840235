import { useAuthState } from "context/auth";
import React from "react";
import { Progress } from "reactstrap";

export default function RegistrationProgress(props) {
  const progressBar = useAuthState();
  return (
    <div className="main-bar mt-5">
      <Progress
        className="main-bar__progress mx-3"
        color="primary"
        value={progressBar.progress}
      />

      <div className="main-bar__box">
        <div
          className={`main-bar__item ${
            progressBar.progress >= 50 ? "active" : ""
          } ${progressBar.progress === 0 ? "current" : ""}`}
        >
          <span className="fa fa-user fa-2x"></span>
        </div>
        <div
          className={`main-bar__item ${
            progressBar.progress > 50 ? "active" : ""
          } ${progressBar.progress === 50 ? "current" : ""}`}
        >
          <span className="fa fa-building fa-2x"></span>
        </div>
        <div
          className={`main-bar__item ${
            progressBar.progress > 100 ? "active" : ""
          } ${progressBar.progress === 100 ? "current" : ""}`}
        >
          <span className="fa fa-shield-alt fa-2x"></span>
        </div>
      </div>
    </div>
  );
}
