import { useEffect, useState } from "react";
import {
  fetchCities,
  fetchCountries,
  fetchPortals,
  fetchTransportars,
  reservationTimes,
  serviceCities,
  testTypes,
} from "services/lookups";
/* import {
  fetchCities,
  fetchCountries,
  fetchPortals,
  fetchTransportars,
  reservationTimes,
  testTypes
} from "../services/looksup"; */

export const LooksupCountries = () => {
  const [countries, setcountries] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchCountries();
      const formatted = res.map((country) => ({
        value: country.id,
        label: country.names[locale],
      }));
      setcountries(formatted);
    }
    fetchLookups();
  }, []);

  return countries;
};

export const LooksupCities = () => {
  const [cities, setcities] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchCities();
      const formatted = res.map((city) => ({
        value: city.id,
        label: city.names[locale],
      }));
      setcities(formatted);
    }
    fetchLookups();
  }, []);

  return cities;
};

export const LooksupGovernorate = () => {
  const [cities, setcities] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchCities();
      const formatted = res.map((city) => ({
        value: city.id,
        label: city.names[locale],
      }));
      setcities(formatted);
    }
    fetchLookups();
  }, []);

  return cities;
};

export const LooksupTransportar = () => {
  const [transportar, setTransportar] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchTransportars();
      const formatted = res.map((transportar) => ({
        value: transportar?.id,
        label: transportar?.names[locale],
      }));
      setTransportar(formatted);
    }
    fetchLookups();
  }, []);

  return transportar;
};

export const LooksupPortals = (id) => {
  const [portals, setPortals] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchPortals(id);
      const formatted = res.map((city) => ({
        value: city.id,
        label: city.names[locale],
      }));
      setPortals(formatted);
    }
    fetchLookups();
  }, []);

  return portals;
};

export const LooksupTestTypes = () => {
  const [testType, setTestType] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await testTypes();
      const formatted = res.checks.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      setTestType(formatted);
    }
    fetchLookups();
  }, []);

  return testType;
};

export const LooksupServicesCities = () => {
  const [cities, setCities] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await serviceCities();
      const formatted = res.cities.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      setCities(formatted);
    }
    fetchLookups();
  }, []);

  return cities;
};

export const LooksupTimeReservation = () => {
  const [timeReservation, setTimeReservation] = useState();
  const locale = localStorage.getItem("currentLanguage") || "ar";
  useEffect(() => {
    async function fetchLookups() {
      const res = await reservationTimes();
      let formatted = [];
      for (let i = 0; i < res.times.length - 1; i++) {
        formatted.push({
          value: res.times[i],
          label: res.times[i] + " - " + res.times[i + 1],
        });
      }
      setTimeReservation(formatted);
    }
    fetchLookups();
  }, []);

  return timeReservation;
};
