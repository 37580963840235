import React from "react";
import Locale from "translations";

export default function Counts({number}) {
    const {translate} = Locale;
    const style = {
        color: '#24AEFB',
        fontSize: '50px'
    }

    return (
        <>
            <div className="count-box bg-white mb-2 p-3 rounded shadow-sm">
                <h6 className="title">{translate.teamManagementCount}</h6>
                <div className="d-flex justify-content-between align-items-center my-4">
                    <div className="img-box">
                        <i class="fas fa-users" style={style}/>
                    </div>
                    <div className="number">{number}</div>
                </div>
            </div>


        </>
    );
}
