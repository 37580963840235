import React from "react";
import Header from "./Header";
export default function Layout(props) {
  const isAuth = localStorage.getItem("isAuth");

  return (
    <>
      {isAuth && <Header />}
      <section>{props.children}</section>
    </>
  );
}
