import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AdminApp from "./AdminApp";
import { createBrowserHistory } from "history";

import { CompanyProvider } from "./context/global";
import { AdminProvider } from "./context/Admin/global";

const history = createBrowserHistory();
console.log('index',history.location.pathname);

if(history.location.pathname.includes("admin")) {
  ReactDOM.render(
    <React.StrictMode>
      <AdminProvider>
        <AdminApp />
      </AdminProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}else{
  ReactDOM.render(
    <React.StrictMode>
      <CompanyProvider>
        <App />
      </CompanyProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}


