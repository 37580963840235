import UserSetting from "components/UsetSetting/UserSetting";
import { useCompanyDispatch, useCompanyState } from "context/global";
import useWallet from "modules/Yarvel/wallet/useWallet";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Locale from "translations";
import logoImg from "assets/images/Image1.png";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
function Header() {
  const { translate } = Locale;
  const { pathname } = useLocation();

  const isAuth = localStorage.getItem("isAuth");
  const dispatch = useCompanyDispatch();
  const balances = useWallet();
  const { balance, safaWallet } = useCompanyState();
  const company_name = localStorage.getItem("company_name");

  return !isAuth ? null : (
    <>
      <header className="header">
        <p className="w-100 py-2 text-white  mb-0 container text-bold">
          مرحباً، {company_name}
        </p>
      </header>
      <div className="w-100 bg-white pt-2">
        <div className="container ">
          <div className="d-flex justify-content-between align-items-baseline">
            <figure className="logo">
              <img className="w-75" src={logoImg} alt="logo" />
            </figure>
            <div className="header-icons">
              {/* <button
                onClick={() => {
                  dispatch({ type: "logout", payload: "logout" });
                }}
              >
                <i class="fas fa-sign-out-alt"></i>
              </button> */}

              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle
                  tag="button"
                  className="h5 text-primary d-inline"
                  caret
                >
                  <i class="fas fa-sign-out-alt"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    tag="button"
                    onClick={() => {
                      dispatch({ type: "logout", payload: "logout" });
                    }}
                  >
                    تسجيل خروج
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* 
              <UserSetting /> */}
            </div>
          </div>
        </div>
      </div>

      <nav className="header ">
        <div className="d-flex justify-content-between align-items-stretch  container ">
          <div className="d-flex  ">
            <NavLink
              exact={true}
              to="/"
              activeClassName="is-active"
              className="mr-4 py-2 text-bold"
            >
              {translate.MainMenu}
            </NavLink>

            <NavLink
              exact={true}
              to="/medical/services"
              isActive={() => {
                return pathname.includes("/medical/services");
              }}
              activeClassName="is-active"
              className="mr-4 py-2  text-bold"
            >
              {translate.MedicalService}
            </NavLink>

            <NavLink
              exact={true}
              to="/customers"
              isActive={() => {
                return (
                  pathname.includes("done") || pathname.includes("customers")
                );
              }}
              activeClassName="is-active"
              className="mr-4 py-2  text-bold"
            >
              {translate.MedicalServiceReq}
            </NavLink>

            <NavLink
              exact={true}
              to="/wallet"
              isActive={() => pathname.includes("wallet")}
              activeClassName="is-active"
              className="mr-4 py-2 text-bold"
            >
              {translate.Wallet}
            </NavLink>

            <NavLink
              exact={true}
              to="/team-management/users"
              activeClassName="is-active"
              isActive={() => pathname.includes("/team-management/users")}
              className="mr-4 py-2 text-bold"
            >
              {translate.teamManagement}
            </NavLink>
          </div>

          <div className="d-flex bg-white border-t p-1">
            <div className="medical_box p-0  d-flex align-items-center mx-2 border">
              <i className="fas fa-wallet fa-lg text_blue-dark mx-1"></i>
              <h5 className=" text_blue-dark m-0 ">
                {translate.safaWallet} :{" "}
              </h5>
              <button className="btn  p-0 text_blue-dark px-1 mx-1 bg-gray">
                {safaWallet} EGP
              </button>
            </div>
            <div className="medical_box p-0 d-flex align-items-center border">
              <i className="fas fa-wallet fa-lg text_blue-dark mx-1"></i>
              <h5 className=" text_blue-dark m-0 ">{translate.Balance} : </h5>
              <button className="btn  p-0 text_blue-dark px-1 mx-1 text-bold bg-gray">
                {balance} EGP
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Header;
