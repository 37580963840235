import TextField from "components/shared/textField";
import validate, { isFormValid } from "helpers/validate";
import LoginBanner from "modules/Yarvel/auth/components/LoginBanner";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { forgetPass } from "services/auth";
import Locale from "translations";

export default function ForgotPassword() {
  // ----------------------------------------------------------------------------------------------------
  // Component States / Variables / Constants
  // ----------------------------------------------------------------------------------------------------
  const { forgetPassword } = Locale;
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [spin, setSpin] = useState(false);

  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------
  // Validate Email
  const [errors, setErrors] = useState({});

  const checkEmailErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: "email", value: email },
        { required: true, email: true }
      )
    });
  };
  // Forgot Password Handler
  const forgorPasswordHandler = async () => {
    checkEmailErrors();
    setSpin(true);
    let requestBody = {
      email: email
    };
    if (isFormValid(errors)) {
      const response = await forgetPass(requestBody);
      if (response.status === 200) {
        setSpin(false);
        history.push({
          pathname: "/auth/forgot_password_submitted",
          state: { email: email }
        });
      }
    }
    setSpin(false);
  };

  // ----------------------------------------------------------------------------------------------------
  // Main Component Body UI
  // ----------------------------------------------------------------------------------------------------
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* Left Banner */}
          <div className="col-md-6 p-0">
            <LoginBanner />
          </div>

          {/* Forget Password Form */}
          <div className="col-md-6 py-5 py-md-0">
            <div className="d-flex align-items-center h-75 px-4 px-md-0 pt-5 pt-md-0">
              <div className="col-md-8 mx-auto text-center">
                <h1 className="text-title">{forgetPassword.resetPassword}</h1>
                <p className="text-headline">{forgetPassword.forgetMessage}</p>

                <div className="my-3">
                  <TextField
                    label={forgetPassword.emailAddress}
                    value={email}
                    placeholder={forgetPassword.emailPlaceholder}
                    name="email"
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        ...validate(e.target, {
                          required: true,
                          email: true
                        })
                      });
                      setEmail(e.target.value);
                    }}
                    color={
                      errors?.email?.required || errors?.email?.email
                        ? "danger"
                        : ""
                    }
                    errors={errors?.email}
                  />
                </div>

                <button
                  className="btn btn-primary w-100"
                  onClick={forgorPasswordHandler}
                  disabled={spin}
                >
                  {spin ? <Spinner color="light" size="sm" /> : null}{" "}
                  {forgetPassword.continue}
                </button>

                <div className="d-flex justify-content-between">
                  <Link to="/login" className="my-1 text-dark-blue">
                    <span className="icx icx-left-solid-arrow text-10"></span>{" "}
                    {forgetPassword.backLogin}
                  </Link>
                  <Link to="/register" className="my-1 text-dark-blue">
                    {forgetPassword.newUserSignup}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
