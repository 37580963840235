import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getClientsReservation, veiwReservation } from "services/yarvel";
import Locale from "translations";
import ViewClient from "./viewClient";

export default function View() {
  const { translate } = Locale;
  const { id } = useParams();
  const [clients, setClients] = useState({});
  const [reservations, setReservations] = useState();
  const [trip, setTrip] = useState();
  const [service, setService] = useState();
  useEffect(() => {
    async function fetchListClients() {
      const list = await veiwReservation(id);

      setClients(list?.data?.data.reservations);
      setTrip(list?.data?.data.Trip);
      setService(list?.data?.data.service);
    }
    fetchListClients();
  }, []);

  const AllClients =
    clients &&
    clients.length > 0 &&
    clients.map((client, index) => (
      <ViewClient client={client} key={index} index={index} />
    ));

  return (
    <>
      <div className="container bg-white py-3 mt-2">
        {/*      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h4 className="text-muted font-weight-bold">{clients?.test?.name}</h4>
      </header> */}

        {/*       <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
        {`${translate.orderData} `}
      </h5> */}

        {service?.check && (
          <div className=" py-2 p-3  border medical_box rounded mt-3">
            <h5 className="font-weight-bold text_blue-dark mt-2">
              بيانات الطلب
            </h5>
            <div className="  row">
              <p className="col-3 h5   font-weight-bold">الفحص</p>
              <p className="col-3 h5 ">{service.check}</p>



              <p className="col-3 h5   font-weight-bold">نوع الخدمة</p>
              <p className="col-3 h5 ">{service.type}</p>

              <p className="col-3  h5  font-weight-bold">درجة الخدمة</p>
              <p className="col-3 h5 ">{service.grade}</p>
       
              <p className="col-3 h5   font-weight-bold">النتيجة خلال</p>
              <p className="col-3 h5 ">24 ساعه</p>
            </div>
          </div>
        )}

        {trip?.is_pcr && (
          <div className="py-2 p-3  border medical_box rounded mt-3">
            <h5 className="font-weight-bold text_blue-dark mt-2">
              بيانات الرحلة
            </h5>
            <div className="row">
              <p className="col-3 h5   font-weight-bold">PNR</p>
              <p className="col-3 h5 ">XD23245</p>

              <p className="col-3 h5   font-weight-bold">
                {translate.DepartureAirport}
              </p>
              <p className="col-3 h5 ">{trip.departure_port_name}</p>

              <p className="col-3 h5   font-weight-bold">
                {translate.destnation}
              </p>
              <p className="col-3 h5 ">{trip.destination_name}</p>
              <p className="col-3 h5  font-weight-bold">
                {translate.departureDate}
              </p>
              <p className="col-3 h5 "> {trip.departure_date}</p>
              <p className="col-3  h5  font-weight-bold">
                {translate.Transporter}
              </p>
              <p className="col-3 h5 ">{trip.transporter_name}</p>
              <p className="col-3  h5  font-weight-bold">
                {translate.ArrivalAirport}
              </p>
              <p className="col-3  h5  ">{trip.arrival_port_name}</p>
              <p className="col-3  h5  font-weight-bold">
                {translate.TripNumber}
              </p>
              <p className="col-3  h5  ">{trip.trip_num}</p>

              <p className="col-3  h5  font-weight-bold">
                {translate.ArrivalTime}
              </p>
              <p className="col-3  h5  ">{trip.arrival_date}</p>
            </div>
          </div>
        )}

        {AllClients}
      </div>
    </>
  );
  
}
