import React, { useState } from "react";
import Locale from "../../translations";

/**
 * @Component PasswordField
 * @Description This component for the password input
 * @Props
 *    -> label: Text label for input field
 *    -> placeholder: Input field placeholder
 *    -> className: HTML classes for input field
 *    -> id: HTML ID
 *    -> color: Bootstrap color
 *    -> value: Input value
 *    -> onBlur: Input Function
 *    -> onChange: Input Function
 */

const PasswordField = (props) => {
  const { translate } = Locale;

  const [showPassword, setShowPassword] = useState(false);
  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(translate.isRequired);
        } else if (k === "min" && v) {
          message.push(
            ` ${translate.atLeast} ${props.min}  ${translate.digits}`
          );
        } else if (k === "password" && v) {
          message.push(translate.ShouldMatch);
        } else if (k === "confirm" && v) {
          message.push(translate.ShouldMatchPassword);
        }
      }
      if (message.length === 1) {
        return `${props.label} ${message[0]}`;
      } else if (message.length > 1) {
        return `${props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };
  return (
    <>
      <div
        className={`text_filed password ${
          props.disabled ? "bg-disabled" : " bg-white"
        } ${props.color ? "error_border" : ""}`}
      >
        {/* Text Field Body */}
        <div className="control-field__body">
          {/* Label */}
          <div>
            {props.hasLabel ? (
              <label
                className={`control-field${
                  props.color ? ` text-${props.color}` : ""
                }`}
              >
                {props.label}
              </label>
            ) : null}
          </div>

          {/* Password Input */}
          <div className="row no-gutters justify-content-between">
            <input
              type={showPassword ? "text" : "password"}
              name={props.name}
              className={(props.className ?? "") + " control-field__input"}
              value={props.value}
              onChange={props.onChange}
              min={props.min}
              max={props.max}
              // onBlur={props.onBlur}
              placeholder={props.placeholder}
              // onKeyDown={props.onEnter}
            />

            {/* Show/Hide Password */}
            <span
              className={
                showPassword
                  ? "eye fas fa-eye text-16 text-gray-300"
                  : " eye fas fa-eye-slash text-16 text-gray-300"
              }
              onClick={() => setShowPassword(!showPassword)}
            ></span>
          </div>
        </div>
      </div>

      {/* Feedback */}
      <small
        className={`control-field__feedback control-field__feedback--${
          props.color
        } ${props.color ? "d-block" : "d-none"} text-danger error-message`}
      >
        {generateErrorsMsgs(props.errors)}
      </small>
    </>
  );
};

export default PasswordField;
