import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;

const lookupsUrl = process.env.REACT_APP_API_URL + "/api/lookups";
const [protocol, domain] = baseURL.split("//");
const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

export const SafarnaURL = process.env.REACT_APP_API_URL+"/api";

export const fetchCountries = async () => {
  return await axios
    .get(`${lookupsUrl}/countries`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchCities = async () => {
  return await axios
    .get(lookupsUrl + `/cities?country_id=20`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchCitiesCountryId = async (CountryId) => {
  return await axios
    .get(lookupsUrl + `/cities?country_id=`+CountryId)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchAreas = async (id) => {
  return await axios
    .get(lookupsUrl + `/areas?city_id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchTransportars = async () => {
  return await axios
    .get(lookupsUrl + `/transporters?type=Air`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchPortals = async (id) => {
  return await axios
    .get(lookupsUrl + `/ports?country_id=${id}&keywords=air`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const testTypes = async () => {
  return await axios
    .get(SafarnaURL + `/pcr/lookups/checks-type`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const serviceCities = async () => {
  return await axios
    .get(SafarnaURL + `/pcr/lookups/cities`)
    .then((res) => res.data)
    .catch((err) => err.response);
};
export const fetchServiceAreas = async (id) => {
  return await axios
    .get(SafarnaURL + `/pcr/lookups/areas?city_id=${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const reservationTimes = async () => {
  return await axios
    .get(SafarnaURL + `/pcr/lookups/lab-times/1`)
    .then((res) => res.data)
    .catch((err) => err.response);
};
