import {
  LooksupCountries,
  LooksupPortals,
  LooksupTransportar,
} from "components/lookups";
import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import TimeDatePicker from "components/shared/TimeDatePicker";
import { useClientsDispatch, useClientsState } from "context/addClient";
import { useCompanyState } from "context/global";
import validate from "helpers/validate";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchPortals } from "services/lookups";
import Locale from "translations";

export default function TripInfo({
  setErrorsTrip,
  checkFormallErrors,
  setDoneCheck,
  doneCheck,
  switchTo,
  setEditing,
  editing,
}) {
  const [tripInfo, settripInfo] = useState({});
  const [startVaild, setstartVaild] = useState(false);
  const [startVaildEdit, setstartVaildEdit] = useState(false);
  const [checkedEdit, setcheckedEdit] = useState(false);
  const { company_id } = useCompanyState();
  const { trip_id } = useParams();
  const { tripInfoState } = useClientsState();
  const [errors, setErrors] = useState({});
  const { translate } = Locale;
  const dispatch = useClientsDispatch();
  const transporter = LooksupTransportar();
  const countries = LooksupCountries();
  const [Arrivalportals, setArrivalPortals] = useState();
  const departPortals = LooksupPortals(20);
  const locale = localStorage.getItem("currentLanguage") || "en";

  let myCurrentDate = new Date();
  let myFutureDate = new Date(myCurrentDate);
 /*  myFutureDate.setDate(myFutureDate.getDate() + 2) */; //myFutureDate is now 8 days in the future
  /* get data from state */
  useEffect(() => {
    if (
      tripInfoState &&
      tripInfoState.hasOwnProperty("destination_id") &&
      tripInfoState.hasOwnProperty("status")
    ) {
      settripInfo({
        pnr: tripInfoState?.pnr,
        trip_num: tripInfoState.trip_num,
        destination_id: {
          value: tripInfoState?.destination_id,
          label: tripInfoState?.destination_name,
        },
        departure_date: moment(tripInfoState.departure_date),
        arrival_date: moment(tripInfoState.arrival_date),
        transporter_id: {
          label: tripInfoState.transporter_name,
          value: tripInfoState.transporter_id,
        },
        departure_port_id: {
          value: tripInfoState.departure_port_id,
          label: tripInfoState.departure_port_name,
        },
        arrival_port_id: {
          value: tripInfoState.arrival_port_id,
          label: tripInfoState.arrival_port_name,
        },
      });
    }
  }, [tripInfoState]);

  /* sending data to state */
  useEffect(() => {
    const sendData = setTimeout(() => {
      dispatch({
        type: "addTrip",
        data: {
          ...tripInfo,
          arrival_date: moment(tripInfo.arrival_date).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          trip_num: parseInt(tripInfo.trip_num),
          arrival_port_id: tripInfo.arrival_port_id?.value,
          departure_date: moment(tripInfo.departure_date).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          departure_port_id: tripInfo.departure_port_id?.value,
          transporter_id: tripInfo.transporter_id?.value,
          company_id: company_id,
          destination_id: tripInfo.destination_id?.value?.toString(),
        },
      });
    }, 1000);
    return () => {
      clearTimeout(sendData);
    };
  }, [tripInfo]);

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      settripInfo({ ...tripInfo, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      settripInfo({ ...tripInfo, [name]: value });
    }
  };

  const checkFormErrorsfileds = () => {
    setErrors({
      ...validate(
        {
          name: "destination_id",
          value: tripInfo.destination_id,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "transporter_id",
          value: tripInfo.transporter_id,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "trip_num",
          value: tripInfo.trip_num,
        },
        {
          required: true,
          number: true,
        }
      ),
      ...validate(
        {
          name: "arrival_date",
          value: tripInfo.arrival_date,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "departure_date",
          value: tripInfo.departure_date,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "departure_port_id",
          value: tripInfo.departure_port_id,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "arrival_port_id",
          value: tripInfo.arrival_port_id,
        },
        {
          required: true,
        }
      ),
    });
    setErrorsTrip(errors);
  };

  // chcek for edit
  const submitEdit = () => {
    setcheckedEdit(!checkedEdit);
  };

  useEffect(() => {
    if (startVaildEdit) {
      checkFormErrorsfileds();
      setEditing(!editing);
    }
    setstartVaildEdit(true);
  }, [checkedEdit]);

  /* fetch Arrivalportals */
  useEffect(() => {
    async function fetchLookups() {
      if (tripInfo?.destination_id?.value) {
        const res = await fetchPortals(tripInfo.destination_id?.value);
        const formatted = res.map((area) => ({
          value: area.id,
          label: area.names[locale],
        }));
        setArrivalPortals(formatted);
      }
    }
    fetchLookups();
  }, [tripInfo.destination_id]);

  // chcek for sumbit
  useEffect(() => {
    if (startVaild) {
      checkFormErrorsfileds();
      setDoneCheck(!doneCheck);
    }
    setstartVaild(true);
  }, [checkFormallErrors]);

  return (
    <div className="p-3 ">
      <div className="list_content">
        <h3 className="text-muted font-weight-bold mb-3">
          {translate.TripData}
        </h3>
        <div className="row">
          <div className="col-3">
            <TextField
              hasLabel={true}
              label={"PNR"}
              type={"text"}
              name="pnr"
              placeholder={translate.PNRPlaceholder}
              value={tripInfo.pnr}
              errors={errors.pnr}
              color={errors.pnr?.required ? "danger" : ""}
              onChange={(e) => {
                settripInfo({
                  ...tripInfo,
                  pnr: e.target.value,
                });
        /*         setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                  }),
                }); */
              }}
            >
              <button className="btn btn-secondary">تحقق</button>
            </TextField>
          </div>
          <div className="col-3">
            <SelectField
              onFocus={true}
              hasLabel={true}
              options={countries}
              name="destination_id"
              label={translate.destnation}
              placeholder={translate.destnationPlaceholder}
              value={tripInfo.destination_id}
              errors={errors?.destination_id}
              color={errors?.destination_id?.required ? "danger" : ""}
              onChange={(e) => {
                handleChange({ name: "destination_id", value: e });
                const { label: value } = e;
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "destination_id", value },
                    {
                      required: true,
                    }
                  ),
                });
              }}
            />
          </div>

          <div className="col-3">
            <SelectField
              onFocus={true}
              hasLabel={true}
              options={transporter}
              name="transporter_id"
              label={translate.Transporter}
              value={tripInfo.transporter_id}
              placeholder={translate.TransporterPlaceholder}
              errors={errors?.transporter_id}
              color={errors?.transporter_id?.required ? "danger" : ""}
              onChange={(e) => {
                handleChange({ name: "transporter_id", value: e });

                setErrors({
                  ...errors,
                  ...validate(
                    { name: "transporter_id", value: e },
                    {
                      required: true,
                    }
                  ),
                });
              }}
            />
          </div>

          <div className="col-3">
            <TextField
              hasLabel={true}
              label={translate.TripNumber}
              placeholder={translate.TripNumberPlaceholder}
              value={tripInfo.trip_num}
              errors={errors.trip_num}
              name="trip_num"
              color={
                errors.trip_num?.required
                  ? "danger"
                  : "" || errors.trip_num?.number
                  ? "danger"
                  : ""
              }
              onChange={(e) => {
                settripInfo({
                  ...tripInfo,
                  trip_num: e.target.value,
                });
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    number: true,
                  }),
                });
              }}
            >
              <i className="fas fa-align-justify"></i>
            </TextField>
          </div>

          <div className="col-3 mt-2">
            <SelectField
              onFocus={true}
              hasLabel={true}
              label={translate.DepartureAirport}
              placeholder={translate.DepartureAirportPlaceholder}
              options={departPortals}
              name="departure_port_id"
              value={tripInfo.departure_port_id}
              errors={errors?.departure_port_id}
              color={errors?.departure_port_id?.required ? "danger" : ""}
              onChange={(e) => {
                handleChange({ name: "departure_port_id", value: e });
                const { label: value } = e;
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "departure_port_id", value },
                    {
                      required: true,
                    }
                  ),
                });
              }}
            />
          </div>
          <div className="col-3 mt-2">
            <TimeDatePicker
              hasLabel={true}
              label={translate.departureDate}
              placeholder={translate.departureDate}
              name="departure_date"
              minDateTime={myFutureDate}
              date={tripInfo.departure_date}
              errors={errors?.departure_date}
              color={errors?.departure_date?.required ? "danger" : ""}
              onChangeDate={(e) => {
                console.log(e);
                settripInfo({
                  ...tripInfo,
                  departure_date: e,
                });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "departure_date", value: e },
                    {
                      required: true,
                    }
                  ),
                });
              }}
            ></TimeDatePicker>
          </div>

          <div className="col-3 mt-2">
            <SelectField
              onFocus={true}
              hasLabel={true}
              options={Arrivalportals}
              name="arrival_port_id"
              value={tripInfo.arrival_port_id}
              label={translate.ArrivalAirport}
              placeholder={translate.ArrivalAirportPlaceholder}
              errors={errors?.arrival_port_id}
              color={errors?.arrival_port_id?.required ? "danger" : ""}
              onChange={(e) => {
                console.log(e);
                handleChange({ name: "arrival_port_id", value: e });
                const { label: value } = e;
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "arrival_port_id", value },
                    {
                      required: true,
                    }
                  ),
                });
              }}
            />
          </div>

          <div className="col-3 mt-2">
            <TimeDatePicker
              hasLabel={true}
              label={translate.ArrivalTime}
              placeholder={translate.ArrivalTime}
              name="arrival_date"
              errors={errors?.arrival_date}
              date={tripInfo.arrival_date}
              color={errors?.arrival_date?.required ? "danger" : ""}
              minDateTime={tripInfo.departure_date}
              onChangeDate={(e) => {
                settripInfo({
                  ...tripInfo,
                  arrival_date: e,
                });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "arrival_date", value: e },
                    {
                      required: true,
                    }
                  ),
                });
              }}
            ></TimeDatePicker>
          </div>
        </div>
        {/* Actions */}
        {trip_id && (
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success mt-3 font-weight-bolder"
              onClick={() => {
                submitEdit();
              }}
            >
              {translate.SaveChanges}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
