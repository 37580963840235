import Header from "components/layout";
import React from "react";

const AuthLayout = (props) => {
  return (
    <div className="">
      <Header />
      <div
        className="row vh-100 align-items-center justify-content-center bg-gray-100 m-0"
        {...props}
      >
        <div className="col-md-6">{props.children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
